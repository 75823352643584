

import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Popconfirm, message } from 'antd';
import MasterModal from '../../Common/MasterModal';
import MasterHeader from '../../Common/MasterHeader';
import InputUseForm from '../../Common/InputUseForm';
import { generateValidationSchema } from '../../Common/validationSchema';
import authQueries from '../../../queries/authQueries';
import { usePaginationStore, userStore } from '../../../store/userStore';
import LoaderCommon from '../../Common/LoaderCommon';
import PaginationComponent from '../../Common/PaginationComponent';
import InputSelectForm from '../../Common/InputSelectForm';
import LeadAssign from './LeadAssign';
// import Search from './Search';
import moment from 'moment';
import SelectSearch from '../../Common/SelectSearch';
import InputSearch from '../../Common/InputSearch';
import ExistingDataModal from './ExistingDataModal';
import { Textarea } from '@nextui-org/react';
import InputTextArea from '../../Common/InputTextArea';


const Walkin = () => {
    const today = moment().format("YYYY-MM-DD")
    const master = userStore();
    const [result, setResult] = useState([]);
    // const [filteredData, setFilteredData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [visible, setVisible] = useState(false);
    const [dateRange, setDateRange] = useState('TODAY');
    const [fromDates, setFromDates] = useState(today);
    const [resData, setResData] = useState();
    const [toDate, setToDate] = useState(today);
    const [open, setOpen] = useState(false);
    const [noteUpdateId, setNoteUpdateId] = useState('');
    const [note, setNote] = useState('');

    // const [comment, setComment] = useState('')
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
   
    const handleValidationClose = () => {
        setOpen(false)
    }

    const WalkinListing = authQueries.useWalkinCustomerList(
        async (response) => {
            setResult(response);
            setLoader(false);
        }
    );

    const districtListing = authQueries.useDistrictMutationList(
         async(response) => {
         }   
     );
        
     const visaTypeListing = authQueries.useVisaTypeMutationList(
         async(response) => {

         }   
     );

    useEffect(() => {
        WalkinListing.mutateAsync({ page: currentPage, size: 25, dateRange, from: fromDates, to: toDate });
    }, [currentPage, dateRange, fromDates, toDate]);

    useEffect(() => {
        districtListing.mutateAsync({ page: 0, size: 25 });
        visaTypeListing.mutateAsync({ page: 0, size: 1000 });
    }, []);

    const dateFilterOptions = [
        { name: 'Today', id: 'TODAY' },
        { name: 'This Week', id: 'THIS_WEEK' },
        { name: 'Month till Date', id: 'MONTH_TO_DATE' },
        { name: 'Current Financial Year', id: 'CURRENT_FY' },
        { name: 'Previous Financial Year', id: 'PREVIOUS_FY' },
        { name: 'Custom Filter', id: 'CUSTOM' },
    ];

    const dateFilterArry = [
        {
            label: "Financial Year",
            name: "name",
            size: 2,            
            state: dateRange,
            setState: setDateRange,
            type: "select",
            input: SelectSearch,
            value: dateFilterOptions
        },
        {
            label: "From Date",
            size: 2,            
            state: fromDates,
            setState: setFromDates,
            type: "date",
            input: InputSearch,
            hidden:  dateRange != "CUSTOM"  
        },
        {
            label: "To Date",
            size: 2,            
            state: toDate,
            setState: setToDate,
            type: "date",
            input: InputSearch,
             hidden: dateRange != "CUSTOM" 
        },
    ]

    
    const inputFields = [
        {
            name: "visa_type_id",
            label: "Visa Type",
            placeholder: "Visa Type",
            size: "6",
            type: InputSelectForm,
            options: visaTypeListing?.data?.data?.items,
            optionProps: { name: "description", value: "id" },
            required: false
        },
        {
            name: "firstname",
            label: "First Name",
            placeholder: "First Name",
            size: "6",
            type: InputUseForm,
            required: true,
            min: 2,
            max: 250
        },
        {
            name: "middlename",
            label: "Middle Name",
            placeholder: "Middle Name",
            size: "6",
            type: InputUseForm,
            required: false,
        },
        {
            name: "lastname",
            label: "Last Name",
            placeholder: "Last Name",
            size: "6",
            type: InputUseForm,
            required: true,
            min: 2,
            max: 250
        },
        {
            name: "email",
            label: "Email",
            placeholder: "Email",
            size: "6",
            type: InputUseForm,
            required: true,
            
        },
        {
            name: "mobile",
            label: "Mobile No",
            placeholder: "Mobile",
            size: "6",
            type: InputUseForm,
            required: true,
        },
        {
            name: "whatsapp_no",
            label: "Whatsapp No",
            placeholder: "Whatsapp No",
            size: "6",
            type: InputUseForm,
            required: false,
        },
        {
            name: "landline_no",
            label: "Landline No",
            placeholder: "Landline No",
            size: "6",
            type: InputUseForm,
            required: false,
        },
        {
            name: "district_id",
            label: "District",
            placeholder: "District",
            size: "6",
            type: InputSelectForm,
            options: districtListing?.data?.data?.items,
            optionProps: { name: "name", value: "id" },
            required: false
        },
       
    ];  

    const { register, reset, handleSubmit, formState: { errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen = async (item) => {
        if (!item) {
            await master.setMaster(null);
            setVisible(true);
            return;
        } else {
            reset(item);
            await master.setMaster(item.id);
            setVisible(true);
        }
    }

    const handleClose = async () => {
        const getvalue = getValues();
        for (const key in getvalue) {
            if (getvalue.hasOwnProperty(key)) {
                setValue(key, "");
            }
        }
        clearErrors();
        await new Promise(resolve => setVisible(false, resolve));
        master.setMaster(null);
    }
    
    const applicationListing = authQueries.useWalkinCustomerChekingInAppli(
        (res) => {
            if(res?.message === "Error"){
                setOpen(true) 
                setResData(res.data);
            }else {
            }

        }
    );

    

    const createMaster = authQueries.useWalkinCustomerCreate((res) => {
        if (res?.message === "Error") {
            res.error.map((error) =>
                Object.values(error).map((errorMessage) =>
                    message.error(errorMessage)
                )
            );
            return;
        }
        if (res?.message === "Email or mobile number already exists") {
            message.error("Email or mobile number already exists");
            return;
        }
        handleClose();
        if (res?.message === "Success") {
            applicationListing.mutateAsync(res.data)
            setNoteUpdateId(res.data.id)
            message.success("Lead created");
            WalkinListing.mutateAsync({ page: currentPage, size: 25 });
        }
    });

    const updateMaster = authQueries.useWalkinCustomerUpdate((response) => {
        if (response?.message === "Error") {
            response.error.map((error, index) =>
                Object.values(error).map((errorMessage, innerIndex) =>
                    message.error(errorMessage)
                )
            );
            return;
        }
        handleClose();
        if (response?.message === "Success") {
            message.success("Successfully updated Data");
            WalkinListing.mutateAsync({ page: currentPage, size: 25 });
        }
    });

    const addNote = () => {
        updateMaster.mutateAsync({id: noteUpdateId, notes: note});
        handleValidationClose();
    }
    const handleLeadAssign = async () => {
        await WalkinListing.mutateAsync({ page: currentPage, size: 25, dateRange, from: fromDates, to: toDate });
    };

    return (
        <div>
            <ExistingDataModal data={resData} open={open} handleClose={handleValidationClose} onAddNote={addNote} setNote={setNote}/>
            <MasterHeader handleOpen={handleOpen} header="New Lead" breadcrumb="Walk-In Lead" />
            <div className="add_clients">
                <div className="row">
                    
                    
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../../assets/images/appointments-today-icon.png")} alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Walk in Lead</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header text-[14px]">Walk in Lead</div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6" />
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div className="table-search-wrpr" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                    {
                        dateFilterArry?.map((item, index)=> {
                            if(item?.blank && !item?.hidden){
                                return(
                                <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                )
                            }
                            if(item?.hidden){
                                return
                            }
                            return(
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.input item={item} />
                                    </div>
                                
                        )})
                    }
                </div>
                                        {loader ? <LoaderCommon /> :
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="5%">#</th>
                                                                        <th>First Name</th>
                                                                        <th>Middle Name</th>
                                                                        <th>Last Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Email</th>
                                                                        <th>Date Created</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items.map((item, index) => (
                                                                        <tr className="ui-sortable-handle" key={index}>
                                                                            <th>{(index + 1)}</th>
                                                                            <td>{item.firstname}</td>
                                                                            <td>{item.middlename}</td>
                                                                            <td>{item.lastname}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td className="action-icons flex gap-2 justify-end">
                                                                                <button onClick={() => { handleOpen(item) }} className="btn-nextrow mb-0">Update</button>
                                                                                <div className='col-span-4'>
                                                                                    <LeadAssign
                                                                                        item={item}
                                                                                        roles={[3]}
                                                                                        selectedRole={3}
                                                                                        Listing={WalkinListing}
                                                                                        label="Assign"
                                                                                        noOffice={true}
                                                                                        onAssign={handleLeadAssign}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <MasterModal
                                            width="50%"
                                            header="Walk-In Lead"
                                            visible={visible}
                                            handleClose={handleClose}
                                            inputFields={inputFields}
                                            reset={reset}
                                            control={control}
                                            handleSubmit={handleSubmit}
                                            register={register}
                                            errors={errors}
                                            getValues={master?.master}
                                            defaultValues={getValues()}
                                            create={createMaster}
                                            update={updateMaster}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Walkin;

