
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import InputUseForm from '../../Common/InputUseForm';
import { generateValidationSchema } from '../../Common/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import authQueries from '../../../queries/authQueries';
import { useApi } from '../../Common/ApiProvider';
import { message } from 'antd';
import InputSelectForm from '../../Common/InputSelectForm';

const CommissionAddingmodal = ({ item }) => {
    const [open, setOpen] = useState(false);

    const {setApiDataAndNotify} = useApi()


    const inputFields = [
        {
            name: "firstsem_amount",
            // label: "First sem Commission",
            // placeholder: "First sem Commission",
            label: item?.visatype?.country?.name === 'Canada' ? "Commission Amount" : "First sem Commission",
            placeholder: item?.visatype?.country?.name === 'Canada' ? "Commission Amount" : "First sem Commission",
            size: "3",
            type: InputUseForm,
            iptype: "number",
            required: true,
        },
 
    ...(item?.visatype?.country?.name != 'Canada' ? [{
        name: "secondsem_amount",
        label: "Second sem Commission",
        placeholder: "Second sem Commission",
        size: "3",
        type: InputUseForm,
        iptype: "number",
        required: false
    }] : []),
          {
            name: "status",
            label: "Status",
            placeholder: "Status",
            size: "4",
            type: InputSelectForm,
            options:[
                { value: 'Full Commission Received', name: 'Full Commission Received'},
                { value: 'Visa Submission Only', name: 'Visa Submission Only'},
                { value: 'Eligible for Full Commission', name: 'Eligible for Full Commission' },
                { value: 'Not Eligible for Commission', name: 'Not Eligible for Commission' },
                { value: 'Eligible for Partial Commission', name: 'Eligible for Partial Commission' }
            ],
            optionProps: { name: "name", value: "value" },
            required: true
        }
    
         
      ];  

    const { register, reset, handleSubmit, formState: { errors }, control, getValues, setValue, clearErrors } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen = () => {
        setOpen(true);
        reset({
            firstsem_amount: item?.commission?.firstsem_amount,
            secondsem_amount: item?.commission?.secondsem_amount,
            status: item?.commission?.status,
        });
    };

    const handleClose = () => {
        setOpen(false);
        reset(null);
    };

    
    

    const CommissionAmount = authQueries.useUpdateFeePayedStd(
        (res)=>{

        }
    );

    const onSubmit = async (data) => {
        data.applicant_id = item.id;
        data.user_id = item.user_id;
       
        try {
            const response = await CommissionAmount.mutateAsync(data);
            handleClose();
            setApiDataAndNotify(response);
            if (response?.message === "Success") {
                message.success("Successfully updated data"); 
            } else {
                message.error("Failed to update data"); 
            }
            
        } catch (error) {
            message.error("An error occurred while updating data");
        }
    };
    

    return (
        <div>
            <button 
            className='btn-customized-large h-[35px] w-[110px]'
            onClick={handleOpen}>
                Add Commission
            </button>
            <Modal
                open={open}
                onCancel={handleClose}
                footer={null}
                width="400px"
            >
                <div className='flex flex-col gap-6'>
                    <div>
                        <div className='text-[14px] font-medium'></div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col justify-center gap-6'>
                            <header>Applicant : {item?.applicant_name}  {item?.lastname}</header>
                            <div className={`grid grid-cols-6 gap-x-6 gap-y-3`}>
                                {inputFields.map((input, index) => (
                                    <div key={index} className={`col-span-${input.size} flex flex-col gap-2`}>
                                        <input.type item={input} register={register} errors={errors} control={control} options={input.options} optionProps={input.optionProps} />
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-end gap-5'>
                                <button
                                    onClick={handleClose}
                                    className='bg-red-500 text-white px-8 py-[8px] rounded-md text-[12px] font-semibold'
                                    type='button'
                                >
                                    Cancel
                                </button>
                                <button
                                    className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold'
                                    type='submit'
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default CommissionAddingmodal;
