import { Modal } from 'antd'
import React from 'react'


const MasterModal = ({width, header, visible, handleClose, inputFields, register, handleSubmit, errors, getValues, control, create, update, grid}) => {

    const onSubmit=(data)=>{
        if(getValues){
            console.log("getvalue",getValues)
            update.mutateAsync(data) 
        }else{
            create.mutateAsync(data) 
        }
    }

  return (
    <div>
        <Modal
        open={visible}
        onCancel={handleClose}
        footer={null}
        width={width}
        >
            <div className='flex flex-col gap-6'>
                <div>
                    <div className='text-[14px] font-medium'>{getValues ? "Update" : "Create"} {header}</div>
                </div>
                <form>
                    <div className='flex flex-col justify-center gap-6'>
                        <div className={`grid grid-cols-${grid ? grid:12} gap-x-6 gap-y-3`}>
                            {
                                inputFields?.map((item, index)=>(
                                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                        <item.type item={item} register={register} errors={errors} control={control}  options={item?.options} optionProps={item?.optionProps} />
                                    </div>
                                ))
                            }
                        </div>
                        <div className='flex justify-end gap-5'>
                            <button 
                            onClick={handleClose} 
                            className='bg-red-500 text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='button'
                            >
                            Cancel
                            </button>
                            <button 
                            onClick={handleSubmit(onSubmit)} 
                            className='bg-primary text-white px-8 py-[8px] rounded-md text-[12px] font-semibold' 
                            type='submit'
                            >
                            {getValues? 'Update': 'Create'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </Modal>
    </div>
  )
}

export default MasterModal

