import React from 'react'
import { userStore } from '../../store/userStore'
import CounselorDashboard from './CounselorDashboard';
import TeleCallerDashboard from './TelecallerDashboard';
import ProgramCoordinatorDashboard from './ProgramCoordinatorDashboard';
import AdmissionDashboard from './AdmissionDashboard';
import FeeCoordinatorDashboard from './FeeCoordinatorDashboard';
import VisaStaffDashboard from './VisaStaffDashboard';
import AdminDashboard from './AdminDashboard';
import BdmDashboard from './BdmDashboard';
import DocumentDashboard from './DocumentDashboard';
import MarketingAssociateDashboard from './MarketingAssociateDashboard';
import BranchManagerListing from '../BranchManager/BranchManager';
import CeoDashboard from './CeoDasboard';
import MarkRegisterDashboard from './MarkRegisterDashboard';
import ManagerDashboard from './ManagerDashboard';
import LoanListing from '../Loan/LoanListing';
import TravelListing from '../Travel/TravelListing';
import InterviewListing from '../Interview/InterviewListing';
import GICListing from '../GIC/GICListing';
import AllSearchApplicant from '../Clients/ApplicantAssign/AllSearchApplicant';
import BranchManagerDashboard from './BranchManagerDashboard';
import ApplicantListing from '../Clients/AddClients/ApplicantListing';
import SeniorProgramManagerDashboard from './SeniorProgramManagerDashboard';
import FairStaffDashboard from './FairStaffDashboard';
import SopStaffDashboard from './SopStaffDashboard';
import RMDashboard from './RMDashboard';
import AccountentDashboard from './AccountentDashboard';

const DashboardLaout =() => {
  const store = userStore();
  return (
    <div>

        {
          store.user.role === 'MANAGER' ? <ManagerDashboard /> : 
          store.user.role === 'TELECALLER' ? <TeleCallerDashboard /> : 
          store.user.role === 'COUNSELOR' ? <CounselorDashboard /> : 
          store.user.role === 'PROGRAMCOORDINATOR' ? <ProgramCoordinatorDashboard /> : 
          store.user.role === 'ADMISSIONSTAFF' ? <AdmissionDashboard /> :
          store.user.role === 'FEECOORDINATOR' ? <FeeCoordinatorDashboard /> :
          store.user.role === 'VISASTAFF' ? <VisaStaffDashboard /> :
          store.user.role === 'ADMIN' ? <AdminDashboard /> :
          store.user.role === 'BDM' ? <BdmDashboard /> :
          store.user.role === 'DOCUMENTSTAFF' ? <DocumentDashboard /> :
          store.user.role === 'MARKETINGASSOCIATE' ? <MarketingAssociateDashboard /> :
          store.user.role === 'REGISTRATION' ? <MarkRegisterDashboard /> :
          store.user.role === 'BMANAGER' ? <BranchManagerDashboard /> :
          store.user.role === 'CEO' ? <CeoDashboard /> :
          store.user.role === 'LOAN' ? <LoanListing /> : 
          store.user.role === 'INTERVIEW' ? <InterviewListing /> :
          store.user.role === 'TRAVEL' ? <TravelListing /> :
          store.user.role === 'GIC' ? <GICListing /> :
          store.user.role === 'FRONTOFFICE' ? <AllSearchApplicant /> :
          store.user.role === 'SENIORPROGRAMMANAGER' ? <SeniorProgramManagerDashboard />:
          store.user.role === 'SOPSTAFF' ? <SopStaffDashboard />:
          store.user.role === 'RM' ? <RMDashboard />:
          store.user.role === 'ACCOUNTS'? <AccountentDashboard />:
          store.user.role === 'FAIRSTAFF' && <FairStaffDashboard />
        }

    </div>

  )
}

export default DashboardLaout