
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import authQueries from '../../../queries/authQueries';
import moment from 'moment';

const StaffsRelatedApplicants = ({ item }) => {
    
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState([]);
//    console.log("item>>>",item)
    
    const RelatedApplicants = authQueries.useRelatedAppli(
        async (response) => {
            setResult(response);
        }
    );
    
    useEffect(() => {
        RelatedApplicants.mutateAsync(item)
    }, []);
  

    const handleOpen = () => {
        setOpen(true);
        RelatedApplicants.mutateAsync(item);

    };

    const handleClose = () => {
        setOpen(false);
     };

    
 
    
    
    return (
        <div>
            <button 
            className='btn-customized-large h-[35px] w-[110px]'
            onClick={handleOpen}>
                Details
            </button>
            <Modal
                open={open}
                onCancel={handleClose}
                footer={null}
                width="auto"
            >
                <div className='flex flex-col gap-6 '>
                    <div>
                        <div className='text-[14px] font-medium'></div>
                    </div>
                    <div className="table-wrpr">
                    <header>Staff Name: {item?.user?.userdetail?.firstname}  {item?.user?.userdetail?.lastname}</header>
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12"> 
                                                    <table border={1} className="table prospect-table">
                                                        <thead>
                                                            <tr className="!font-bold">
                                                                <th width="30">#</th>
                                                                <th>Applicant Name</th>
                                                                <th>Applicant Mobile</th>
                                                                <th>College</th>
                                                                <th>Commission</th>
                                                                <th>paid date </th>
                                                                
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  className="ui-sortable">
                                                        {   
                                                                result?.data?.map((data, index) =>(
                                                                    <tr className="ui-sortable-handle">
                                                                        <td>{(index + 1)}</td>
                                                                        <td>{data?.application?.applicant_name} {data?.application?.lastname}</td>
                                                                        <td>{data?.application?.mobile_no}</td>
                                                                        <td>
                                                                            {data?.application?.intended_programs?.map((program, programIndex) => (
                                                                                <div key={programIndex}>{program?.college?.name}</div>
                                                                            ))}
                                                                        </td> 
                                                                        <td>{data?.amount_paid}</td>
                                                                        <td>{moment(data?.paid_date).format('DD-MM-YYYY')}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>        
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                </div>
            </Modal>
        </div>
    );
};

export default StaffsRelatedApplicants;

