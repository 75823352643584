import React, { useEffect, useState } from 'react';
import LoaderCommon from '../Common/LoaderCommon';
import PaginationComponent from '../Common/PaginationComponent';
import authQueries from '../../queries/authQueries';
import { usePaginationStore, userStore } from '../../store/userStore';
import moment from 'moment';
import CommissionAddingmodal from '../Master/Accounts/CommissionAddingmodal';
import { useApi } from '../Common/ApiProvider'; 
import ViewRelatedStaffModal from '../Master/Accounts/ViewRelatedStaffModal';



const AccountentDashboard = () => {
    const [result, setResult] = useState([]);
    const [loader, setLoader] = useState(true);
console.log("data",result)
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
 
    const {apiData} = useApi()


    const Listing = authQueries.useUniversityFeePayedStd(
      async (response) => {
          setResult(response);
          setLoader(false);
      }
  ); 
 
  useEffect(() => {
    Listing.mutateAsync({ 
        page: currentPage, 
        size: 25 
    });
}, [currentPage, apiData]);


    return (
        <div>
            <div className="add">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                       
                        <h2><span>&nbsp;</span>Commission received from colleges</h2>
                    </div>


                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        {/* <div className="card-header text-[14px]">Commission Received</div> */}
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                <div className="col-md-6" />
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div className="table-search-wrpr" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="total-data-count">
                                            Total records : {result?.data?.totalItems || 0}
                                        </div>
                                        {
                                        loader ? <LoaderCommon /> :
                                            result?.data?.totalItems > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <table className="table prospect-table"> 
                                                                <thead>
                                                                    <tr>
                                                                        <th width="5%">SL No</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Applicant Mobile</th>
                                                                        <th>Type of Visa</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Visa Approved/Refused On</th>
                                                                        <th>College</th>
                                                                        <th>First Sem Commission</th>
                                                                        <th>First commission Received Date</th>
                                                                        <th>Second Sem Commission</th>
                                                                        <th>Second Commission Received Date</th>
                                                                        <th>Status</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((item, index) => (
                                                                        <tr className="ui-sortable-handle" key={index}>
                                                                            <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                            <td>{item?.applicant_name} {item?.lastname}</td>
                                                                            <td>{item?.mobile_no}</td>
                                                                            <td>{item?.visatype?.description}</td>
                                                                            <td>{item?.status_of_prospect?.name}</td>
                                                                            <td>{moment(item?.applicant_prospects_statuses?.[0]?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td>
                                                                              {item?.intended_programs?.map((program, programIndex) => (
                                                                                <div key={programIndex}>{program?.college?.name}</div>
                                                                              ))}
                                                                            </td>                                                                          
                                                                            <td>{item?.commission?.firstsem_amount}</td>
                                                                            <td>{item?.commission?.firstsem_amount_recvd_date ? moment(item?.commission?.firstsem_amount_recvd_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                                            <td>{item?.commission?.secondsem_amount}</td>
                                                                            <td>{item?.commission?.secondsem_amount_recvd_date ? moment(item.commission.secondsem_amount_recvd_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                                            <td>{item?.commission?.status}</td>
                                                                            <td className="action-icons flex gap-2 justify-end">
                                                                            <CommissionAddingmodal item={item}/>
                                                                            <ViewRelatedStaffModal item={item}/>
                                                                             
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             : <p>No data available.</p>
                                        }
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountentDashboard;
