
import { Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import authQueries from '../../../queries/authQueries';
import { useApi } from '../../Common/ApiProvider';
import moment from 'moment';
import LoaderCommon from '../../Common/LoaderCommon';

const ViewRelatedStaffModal = ({ item }) => {
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState([]);

    const { setApiDataAndNotify } = useApi();
    const [editedData, setEditedData] = useState({});
    const [editIndex, setEditIndex] = useState(null);
    const [loader, setLoader] = useState(true);
    const [dataAdded, setDataAdded] = useState(false);
    const [errorAmount, setErrorAmount] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const RelatedStaffes = authQueries.useAppliRelatedStaffes(
        async (response) => {
            setResult(response);
            setLoader(false);
        }
    );

    const CommissionAmount = authQueries.useCommissionPayed(
        (res) => {

        }
    );

    useEffect(() => {
        RelatedStaffes.mutateAsync(item);
    }, []);

    const handleOpen = () => {
        setOpen(true);
        setLoader(true);
        RelatedStaffes.mutateAsync(item.id);
    };

    const handleClose = () => {
        setOpen(false);
        cancelEdit();
    };

    const handleAddClick = (index) => {
        const staffData = result?.data[index];
        setEditIndex(index);
        setEditedData({
            applicant_id: staffData?.applicant_id,
            user_id: staffData?.user?.id,
            amount_paid: staffData?.user?.commissiondetail?.amount_paid,
            bank: staffData?.user?.commissiondetail?.bank,
            role_id: staffData?.role_id,
            assign_id: staffData?.id,
        });
        setDataAdded(true);
    };

    const handleEdit = (index) => {
        setErrorMessage('');
        setErrorAmount('');
        const staffData = result?.data[index];
        setEditIndex(index);
        setEditedData({
            id: staffData?.commissiondetail?.id,
            applicant_id: staffData?.applicant_id,
            user_id: staffData?.user?.id,
            amount_paid: staffData?.commissiondetail?.amount_paid,
            bank: staffData?.commissiondetail?.bank,
        });
    };

    const  handleSave = async () => {
        if (!editedData.bank) {
            setErrorMessage('Payment details required.');
             
            
        }if (!editedData.amount_paid) {
            setErrorAmount('Amount required.');
            return; 
            
        }

        try {
            const response = await CommissionAmount.mutateAsync(editedData);
            if (response?.message === "Success") {
                setErrorMessage('');
                setErrorAmount('');
                const updatedData = result?.data.map((item, index) => 
                    index === editIndex 
                        ? { 
                            ...item, 
                            user: {
                                ...item.user,
                                commissiondetail: {
                                    ...item.user.commissiondetail,
                                    amount_paid: editedData.amount_paid,
                                    bank: editedData.bank
                                }
                            }
                        }
                        : item
                );
                setResult({ data: updatedData });
                setApiDataAndNotify(response);
                RelatedStaffes.mutateAsync(item.id);
                message.success("Successfully updated data");
                cancelEdit();
                setDataAdded(true);
            } else {
                message.error("Failed to update data");
            }
        } catch (error) {
            message.error("An error occurred while updating data");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const cancelEdit = () => {
        setErrorMessage('');
        setErrorAmount('');
        setEditIndex(null);
        setEditedData({});
    };

    return (
        <div>
            <button 
                className='btn-customized-large h-[35px] w-[110px]'
                onClick= {()=>{handleOpen()}}
            >
                Assigned Staff
            </button>
            <Modal
                open={open}
                onCancel={handleClose}
                footer={null}
                width="auto"
            >
                <div className='flex flex-col gap-6'>
                {loader ? (
                    <LoaderCommon />
                ) : (
                    result?.data?.length > 0 ? (
                    <div className="table-wrpr">
                    <header>Applicant : {item?.applicant_name}  {item?.lastname}</header>
                    <header>Phone  : {item?.mobile_no}</header>
                        <div className="table-section">
                            <div className="row">
                                <div className="col-md-12"> 
                                    <table border={1} className="table prospect-table">
                                        <thead>
                                            <tr className="!font-bold">
                                                <th width="10">#</th>
                                                <th>Staff Name</th>
                                                <th>Role</th>
                                                <th>Commission Paid</th>
                                                <th>Paid Date</th>
                                                <th  width="20">Payment Details</th>
                                                {/* <th>Status</th> */}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="ui-sortable">
                                            {result?.data?.map((data, index) => (
                                                <tr key={index} className="ui-sortable-handle">
                                                    <td>{(index + 1)}</td>
                                                    <td>{data?.user?.userdetail?.firstname} {data?.user?.userdetail?.lastname}</td>
                                                    <td>{data?.role?.label}</td>
                                                    <td>
                                                        {editIndex === index ? (
                                                            <span className='flex flex-col space-y-2'>
                                                            <input
                                                                type="number"
                                                                name="amount_paid"
                                                                value={editedData.amount_paid || ''}
                                                                onChange={handleInputChange}
                                                                className="w-20 h-10"
                                                            />
                                                            {errorAmount && <span className="text-red-500">{errorAmount}</span>}
                                                            </span>
                                                        ) : (
                                                            data?.commissiondetail?.amount_paid
                                                        )}
                                                    </td>
                                                    <td>{data?.commissiondetail?.paid_date? moment(data?.commissiondetail?.paid_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                    {/* <td>
                                                        {editIndex === index ? (
                                                            <input
                                                                type="textarea"
                                                                name="bank"
                                                                value={editedData.bank || ''}
                                                                onChange={handleInputChange}
                                                                className="w-20 h-10"
                                                            />
                                                        ) : (
                                                            data?.commissiondetail?.bank
                                                        )}
                                                    </td> */}
                                                    <td ><div className='w-40'>
                                                        {editIndex === index ? (
                                                            <span className='flex flex-col space-y-2'>
                                                            <textarea
                                                                name="bank"
                                                                value={editedData.bank || ''}
                                                                onChange={handleInputChange}
                                                                className="w-20 h-10"
                                                                required
                                                            />
                                                            {errorMessage && <span className="text-red-500">{errorMessage}</span>}
                                                        </span>
                                                        ) : (
                                                            data?.commissiondetail?.bank
                                                        )}
                                                       
                                                        </div>
                                                    </td>
                                                    {/* <td>{data?.user?.commissiondetail?.application?.commission?.status}</td> */}
                                                    <td className='raw w-40'>
                                                        {editIndex === index ? (
                                                            <div className='flex items-center'>
                                                                <button className='btn-customized-large mr-2' onClick={handleSave}>Save</button>
                                                                <button className='btn-customized-large' onClick={cancelEdit}>Cancel</button>
                                                            </div>
                                                        ) : (
                                                            data?.commissiondetail?.amount_paid && data?.commissiondetail?.bank ? (
                                                                <div className='flex items-center'>
                                                                    <button className='btn-customized-large mr-2' onClick={() => handleAddClick(index)}>Add</button>
                                                                    <button className='btn-customized-large' onClick={() => handleEdit(index)}>Edit</button>
                                                                </div>
                                                            ) : (
                                                                <div className='flex items-center'>
                                                                    <button className='btn-customized-large' onClick={() => handleAddClick(index)}>Add</button>
                                                                </div>
                                                            )
                                                            // <div className='flex items-center'>
                                                            //     <button className='btn-customized-large mr-2' onClick={() => handleAddClick(index)}>Add</button>
                                                            //     <button className='btn-customized-large' onClick={() => handleEdit(index)}>Edit</button>
                                                            // </div>
                                                        )}
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>        
                                    </table>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                      ) : (
                        <p>No data available.</p>
                    )
                )}
                </div>
            </Modal>
        </div>
    );
};

export default ViewRelatedStaffModal;

