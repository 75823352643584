import { GanttChartSquare } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import LoaderCommon from '../../Common/LoaderCommon';
import PaginationComponent from '../../Common/PaginationComponent';
import authQueries from '../../../queries/authQueries';
import { usePaginationStore } from '../../../store/userStore';

import StaffsRelatedApplicants from './StaffsRelatedApplicants';

export default function CommissionReport() {
    const [result, setResult] = useState([]);
    const [loader, setLoader] = useState(true);
    const [staff, setStaff] = useState();
    // const [forFiltter, setForFiltter] = useState([]);
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    

const Listing = authQueries.useCommissionRep(
        async (response) => {
            setResult(response);
            setLoader(false);
        }
    ); 

    // const ForFilttering = authQueries.useCommissionFiltter(
    //     async (response) => {
    //         setForFiltter(response);
    //     });
  
   
    useEffect(() => {
        Listing.mutateAsync({ staff: staff });
    }, [staff]);


  return (
    <div>
       <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Report</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Staff Commission Report</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        {/* <div className="card-header">
                       Commission Report
                        </div> */}
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                
                            {/* <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                            <select style={{ minWidth: '200px' }} onChange={(e) => setStaff(e.target.value)} className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5" placeholder="Search Staff">
                                        <option value="">Select Staff</option>
                                        {
                                            forFiltter?.data?.map((data, index) => (
                                                <option key={index} value={data?.user?.id}>{data?.user?.userdetail?.firstname+' : '+data?.user?.role?.name}</option>
                                            ))
                                        }
                                    </select>
                                        </div> */}

                                <div style={{ display: 'flex', gap: '10px' }}>
                                   
                                </div>


                                {/* <div className="search-results-count-wrpr">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 100 + 1}-${result?.data?.currentPage * 100 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                </div> */}

                                        {/* <div className="total-data-count">
                                            Total records : {result?.data?.totalItems || 0}
                                        </div>    */}
                            {
                                Listing?.isLoading ?
                                <div className='my-10' style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                    <LoaderCommon />
                                </div>:
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">                                   
                                                    <table border={1} className="table prospect-table">
                                                        <thead>
                                                            <tr className="!font-bold">
                                                                <th width="30">#</th>
                                                                <th>Staff</th>
                                                                {/* <th>Role</th> */}
                                                                <th>Total Received Commission </th>
                                                                <th>View Related Applicants</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  className="ui-sortable">
                                                        {   
                                                                result?.data?.map((data, index) =>(
                                                                    <tr key={data?.user?.id || index} className="ui-sortable-handle">
                                                                        <td>{(index + 1)}</td>
                                                                        <td>{data?.user?.userdetail?.firstname} {data?.user?.userdetail?.lastname} </td>
                                                                        {/* <td>{data?.role?.label}</td>  */}
                                                                         <td>{data?.total_amount_paid}</td> 
                                                                      
                                                                         <td><StaffsRelatedApplicants item={data}/></td>                                                                  
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
