import { Modal } from '@nextui-org/react'
// import { IconAlertCircle, IconAlertTriangle, IconCheck, IconCircleX, IconExclamationCircle } from '@tabler/icons-react'
import React, { Fragment, useEffect, useState } from 'react'
import { decode } from 'html-entities'
import moment from 'moment'
// import { Tooltip } from 'antd'
// import authQueries from '../../../queries/authQueries'
// import InputTextArea from '../../Common/InputTextArea';

const ExistingDataModal = ({data, open, handleClose, setNote, onAddNote}) => {

    
  
  return (
        <Modal
        closeButton
        aria-labelledby="modal-title"
        width='80%'
        scroll
        open={open}
        onClose={handleClose}
        >
        <Modal.Header>
            <div className="text-[14px] font-semibold">
                 Existing Applicant Detail
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className="table-wrpr w-full">
                <div className="table-section">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table prospect-table">
                                <thead>
                                    <tr className='alt-colors'>
                                        <th width="2%">#</th>
                                        <th>Office</th>
                                        <th>Date Created</th>
                                        <th>Applicant Name</th>
                                        <th>Contact Number</th>
                                        <th>Visa Type</th>
                                        <th>Intake</th>
                                        <th>Counselor</th>
                                        <th>Reg. Date</th>
                                        <th>Prospect Status</th>
                                        <th>Reffered By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, index) => {
                                        const counselor = item?.assignUser?.CounselorAssign?.userdetail
                                        return(
                                            <tr className="ui-sortable">
                                                <td>
                                                   
                                                        {index + 1}
                                                </td>
                                                <td>{item?.office?.name}</td>
                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                <td>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</td>
                                                <td>
                                                    <div>{item?.mobile_no}</div>
                                                    <div>{item?.whatsapp_no}</div>
                                                    <div>{item?.landline_no}</div>
                                                </td>
                                                <td>{item?.visatype?.description}</td>
                                                <td>{decode(item?.intake?.intake)}</td>
                                                <td>{counselor?.firstname} {counselor?.lastname}</td>
                                                <td>
                                                    {item?.registered_date? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                </td>
                                                <td>{item?.status_of_prospect?.name}</td>
                                                <td>{item?.refferal?.refferal}</td>
                                            </tr>
                                        )}
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
        
 
                <textarea
                type='text'
                defaultValue={`This applicant already exists in the application table`} 
                onChange={(e)=> {setNote(e.target.value)}} 
                placeholder="Enter note" 
                style={{ width: '100%', maxWidth: '1000px' }}
            />
           

            <button onClick={onAddNote}>Update Note</button>
        </Modal.Footer>
        </Modal>
  )
}

export default ExistingDataModal

