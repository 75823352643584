import React, {Fragment, useEffect, useState} from 'react'
import authQueries from '../../../queries/authQueries';
import { Checkbox, Empty, message, Button as Buttons, Modal as Modals, Upload } from 'antd'
import { useLoaderStore, usePaginationStore, userStore } from '../../../store/userStore';
import { useApi } from '../../Common/ApiProvider';
import moment from 'moment';
import LoaderCommon from '../../Common/LoaderCommon';
import LeadStatus from '../../Common/LeadStatus';
import { decode } from 'html-entities';
import ProspectStatusList from '../../Common/ProspectStatusList';
// import FollowUp from '../../Common/FollowUp';
import PaginationComponent from '../../Common/PaginationComponent';
import { useNavigate } from 'react-router-dom';
// import ApplicantEditButton from '../../Common/ApplicantEditButton';
// import LeadEditingLayout from './LeadEditingLayout';


export default function WalkinData() {
    const [visible, setVisible] = useState(false);
    const [result, setResult] = useState([]);
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const storeData = userStore()
    const navigate = useNavigate()


    const listWalkins = authQueries.useListWalkinDataForUser(
        (response) => {
            setLoader(false)
            setResult(response)
            console.log("res>>>>",response)
        }
    );
    useEffect(() => {
        listWalkins.mutateAsync({
            page: currentPage,
            size: 25,
            show: "ALL",
            
        })
    }, [currentPage ])

    const navigateApplicant = (item) => {
        storeData.setApplication("")
        storeData.setApplicationAllData(
            {
                id: item?.id,
                applicant_name: item?.firstname ? item?.firstname : "",
                middlename: item?.middlename ? item?.middlename : "",
                lastname: item?.lastname ? item?.lastname : "",
                email: item?.email,
                mobile_no: item?.mobile,
                // referral_id: item?.refferal?.id,
            }
        )
        navigate(`/walkineditform`)
    }


  return (
   <Fragment>
    <div className="add_clients">
        <div className="row">
            <div className="col-md-6 appointments-hing-wrpr">
                <i className="appointments-icon">
                    <img src={require('../../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                </i>
                <h2><span>My</span>Walkin Leads</h2>
            </div>
            <div className="col-md-12">
                <hr className="progress-hr" />
            </div>
        </div>

        <div id="accordion" className="add_clients_accordion_form">
            <div className="card" id="appointments_wrpr">
                <div className="card-header flex justify-between">
                    <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                        My Walkin Leads
                    </a>
                   
                </div>
                <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">

                       

                            {
                                loader ? 
                                <LoaderCommon />:
                                result?.data?.totalItems > 0 ?
                                <div className="table-wrpr">
                                    <div className="table-section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table prospect-table">
                                                    <thead>
                                                        <tr className='alt-colors'>
                                                            <th width="30">#</th>
                                                            
                                                            <th>Date Created</th>
                                                            <th>Lead Name</th>
                                                            <th>Contact Number</th>
                                                            <th>Visa Type</th>
                                                            <th>District</th>
                                                            <th width='150'>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-sortable">
                                                        {result?.data?.items?.map((item, index) => (
                                                            <Fragment key={index}>
                                                                <tr>
                                                                    <th className="">{(index + 1) + (result.data?.currentPage * 25)}</th>
                                                                   
                                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                    <td>
                                                                        {decode(item?.firstname)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                                    </td>
                                                                    
                                                                    <td>
                                                                        <div className="email-mobile flex flex-col">
                                                                            <div>{item.mobile}</div>
                                                                            <div>{item.whatsapp_no}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.visatype?.description}</td>
                                                                    <td>{item.district?.name}</td>
                                                                   <td>
                                                                   <Buttons onClick={()=>{navigateApplicant(item)}} style={{background:'#FFC75E'}} type="primary" icon={<i className="icon-pencil"></i>} size={10} />

                                                                   </td>
                                                                </tr>

                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                               
                                                <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='mt-24 mb-24'>
                                    <Empty
                                    description={<div className="font-sans text-sm text-blue-700">Clients not found!</div>}
                                    />
                                </div>
                            }

                        </div>

                    </div>
                </div>
            </div>
            
        </div>
</Fragment>
  )
}
