import authService from "../services/authService";
import { useMutation } from "react-query";

const useRegisterMutation = (oData) => {
  return useMutation(authService.register, {
    onSuccess: (data) => oData(data),
  });
};

const useLoginMutation = (onLoginSuccess) => {
  return useMutation(authService.login, {
    onSuccess: (data) => {
      onLoginSuccess(data);
    },
  });
};

const useForgotMutation = (onForgotSuccess) => {
  return useMutation(authService.forgot, {
    onSuccess: (data) => {
      onForgotSuccess(data);
    },
  });
};
  

const useUserListMutation = (onUserData) => {
  return useMutation(authService.userList, {
    onSuccess: (data) => {
      onUserData(data);
    },
  });
};

const useUserFullListMutation = (onUserFullData) => {
  return useMutation(authService.userFullList, {
    onSuccess: (data) => {
      onUserFullData(data);
    },
  });
};

const useUserBranchList = (onUserFullData) => {
  return useMutation(authService.userBranchList, {
    onSuccess: (data) => {
      onUserFullData(data);
    },
  });
};

const useCountMutation = (onCountData) => {
  return useMutation(authService.counts, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useCounselorDashboardList = (onCountData) => {
  return useMutation(authService.counselorDashboard, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useCounselorAppoinmentDashboardList = (onCountData) => {
  return useMutation(authService.counselorAppoinmentDashboard, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useAdminUserMutationList = (onCountData) => {
  return useMutation(authService.adminUserList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useAdminVisaMutationList = (onCountData) => {
  return useMutation(authService.adminVisaList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useAdminStatusMutationList = (onCountData) => {
  return useMutation(authService.adminStatusList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useAdminOfficeMutationList = (onCountData) => {
  return useMutation(authService.adminOfficeList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useAdminRolesMutationList = (onCountData) => {
  return useMutation(authService.adminRolesList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useAdminClientMutationList = (onCountData) => {
  return useMutation(authService.adminClientList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useFeeTypeMutationList = (onCountData) => {
  return useMutation(authService.feeTypeList, {
    onSuccess: (data) => {
      onCountData(data);
    },
  });
};

const useCollegeMutationList = (onCollegeData) => {
  return useMutation(authService.college, {
    onSuccess: (data) => {
      onCollegeData(data);
    },
  });
};

const useCollegeCountryMutationList = (onCollegeData) => {
  return useMutation(authService.countryWisecollege, {
    onSuccess: (data) => {
      onCollegeData(data);
    },
  });
};

const useCollegeMutationView = (onCollegeData) => {
  return useMutation(authService.viewCollege, {
    onSuccess: (data) => {
      onCollegeData(data);
    },
  });
};

const useCollegeMutationAdd = (onCollegeData) => {
  return useMutation(authService.addCollege, {
    onSuccess: (data) => {
      onCollegeData(data);
    },
  });
};

const useCollegeMutationEdit = (onCollegeData) => {
  return useMutation(authService.editCollege, {
    onSuccess: (data) => {
      onCollegeData(data);
    },
  });
};

const useCollegeMutationDelete = (onCollegeData) => {
  return useMutation(authService.deleteCollege, {
    onSuccess: (data) => {
      onCollegeData(data);
    },
  });
};

const useQualificationMutationList = (onQualificationData) => {
  return useMutation(authService.qualification, {
    onSuccess: (data) => {
      onQualificationData(data);
    },
  });
};

const useQualificationMutationView = (onQualificationData) => {
  return useMutation(authService.viewQualification, {
    onSuccess: (data) => {
      onQualificationData(data);
    },
  });
};

const useQualificationMutationAdd = (onQualificationData) => {
  return useMutation(authService.addQualification, {
    onSuccess: (data) => {
      onQualificationData(data);
    },
  });
};

const useQualificationMutationEdit = (onQualificationData) => {
  return useMutation(authService.editQualification, {
    onSuccess: (data) => {
      onQualificationData(data);
    },
  });
};

const useQualificationMutationDelete = (onQualificationData) => {
  return useMutation(authService.deleteQualification, {
    onSuccess: (data) => {
      onQualificationData(data);
    },
  });
};

const useCountryMutationList = (onCoutryData) => {
  return useMutation(authService.country, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useCountryMutationView = (onCoutryData) => {
  return useMutation(authService.viewCountry, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useCountryMutationAdd = (onCoutryData) => {
  return useMutation(authService.addCountry, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useCountryMutationEdit = (onCoutryData) => {
  return useMutation(authService.editCountry, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useCountryMutationDelete = (onCoutryData) => {
  return useMutation(authService.deleteCountry, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useNocMutationList = (onNocData) => {
  return useMutation(authService.noc, {
    onSuccess: (data) => {
      onNocData(data);
    },
  });
};

const useNocMutationView = (onNocData) => {
  return useMutation(authService.viewNoc, {
    onSuccess: (data) => {
      onNocData(data);
    },
  });
};

const useNocMutationAdd = (onNocData) => {
  return useMutation(authService.addNoc, {
    onSuccess: (data) => {
      onNocData(data);
    },
  });
};

const useNocMutationEdit = (onNocData) => {
  return useMutation(authService.editNoc, {
    onSuccess: (data) => {
      onNocData(data);
    },
  });
};

const useNocMutationDelete = (onNocData) => {
  return useMutation(authService.deleteNoc, {
    onSuccess: (data) => {
      onNocData(data);
    },
  });
};

const useOfficeMutationList = (onOfficeData) => {
  return useMutation(authService.office, {
    onSuccess: (data) => {
      onOfficeData(data);
    },
  });
};

const useOfficeMutationView = (onOfficeData) => {
  return useMutation(authService.viewOffice, {
    onSuccess: (data) => {
      onOfficeData(data);
    },
  });
};

const useOfficeMutationAdd = (onOfficeData) => {
  return useMutation(authService.addOffice, {
    onSuccess: (data) => {
      onOfficeData(data);
    },
  });
};

const useOfficeMutationEdit = (onOfficeData) => {
  return useMutation(authService.editOffice, {
    onSuccess: (data) => {
      onOfficeData(data);
    },
  });
};

const useOfficeMutationDelete = (onOfficeData) => {
  return useMutation(authService.deleteOffice, {
    onSuccess: (data) => {
      onOfficeData(data);
    },
  });
};

const useProgramMutationList = (onProgramData) => {
  return useMutation(authService.program, {
    onSuccess: (data) => {
      onProgramData(data);
    },
  });
};

const useProgramMutationView = (onProgramData) => {
  return useMutation(authService.viewProgram, {
    onSuccess: (data) => {
      onProgramData(data);
    },
  });
};

const useProgramMutationAdd = (onProgramData) => {
  return useMutation(authService.addProgram, {
    onSuccess: (data) => {
      onProgramData(data);
    },
  });
};

const useProgramMutationEdit = (onProgramData) => {
  return useMutation(authService.editProgram, {
    onSuccess: (data) => {
      onProgramData(data);
    },
  });
};

const useProgramMutationDelete = (onProgramData) => {
  return useMutation(authService.deleteProgram, {
    onSuccess: (data) => {
      onProgramData(data);
    },
  });
};

const useVisaTypeMutationList = (onVisaTypeData) => {
  return useMutation(authService.visaType, {
    onSuccess: (data) => {
      onVisaTypeData(data);
    },
  });
};

const useVisaTypeMutationView = (onVisaTypeData) => {
  return useMutation(authService.viewVisaType, {
    onSuccess: (data) => {
      onVisaTypeData(data);
    },
  });
};

const useVisaTypeMutationAdd = (onVisaTypeData) => {
  return useMutation(authService.addVisaType, {
    onSuccess: (data) => {
      onVisaTypeData(data);
    },
  });
};

const useVisaTypeMutationEdit = (onVisaTypeData) => {
  return useMutation(authService.editVisaType, {
    onSuccess: (data) => {
      onVisaTypeData(data);
    },
  });
};

const useVisaTypeMutationDelete = (onVisaTypeData) => {
  return useMutation(authService.deleteVisaType, {
    onSuccess: (data) => {
      onVisaTypeData(data);
    },
  });
};


const useDistrictMutationList = (onDistrictData) => {
  return useMutation(authService.district, {
    onSuccess: (data) => {
      onDistrictData(data);
    },
  });
};

const useDistrictMutationView = (onDistrictData) => {
  return useMutation(authService.viewDistrict, {
    onSuccess: (data) => {
      onDistrictData(data);
    },
  });
};

const useDistrictMutationAdd = (onDistrictData) => {
  return useMutation(authService.addDistrict, {
    onSuccess: (data) => {
      onDistrictData(data);
    },
  });
};

const useDistrictMutationEdit = (onDistrictData) => {
  return useMutation(authService.editDistrict, {
    onSuccess: (data) => {
      onDistrictData(data);
    },
  });
};

const useDistrictMutationDelete = (onDistrictData) => {
  return useMutation(authService.deleteDistrict, {
    onSuccess: (data) => {
      onDistrictData(data);
    },
  });
};

const useRoleMutationList = (onRoleData) => {
  return useMutation(authService.role, {
    onSuccess: (data) => {
      onRoleData(data);
    },
  });
};

const useRoleMutationView = (onRoleData) => {
  return useMutation(authService.viewRole, {
    onSuccess: (data) => {
      onRoleData(data);
    },
  });
};

const useRoleMutationAdd = (onRoleData) => {
  return useMutation(authService.addRole, {
    onSuccess: (data) => {
      onRoleData(data);
    },
  });
};

const useRoleMutationEdit = (onRoleData) => {
  return useMutation(authService.editRole, {
    onSuccess: (data) => {
      onRoleData(data);
    },
  });
}

const useRoleMutationDelete = (onRoleData) => {
  return useMutation(authService.deleteRole, {
    onSuccess: (data) => {
      onRoleData(data);
    },
  });
};

const useUniversityMutationList = (onUniversityData) => {
  return useMutation(authService.university, {
    onSuccess: (data) => {
      onUniversityData(data);
    },
  });
};


const useUniversityMutationView = (onUniversityData) => {
  return useMutation(authService.viewUniversity, {
    onSuccess: (data) => {
      onUniversityData(data);
    },
  });
};

const useUniversityMutationAdd = (onUniversityData) => {
  return useMutation(authService.addUniversity, {
    onSuccess: (data) => {
      onUniversityData(data);
    },
  });
};

const useUniversityMutationEdit = (onUniversityData) => {
  return useMutation(authService.editUniversity, {
    onSuccess: (data) => {
      onUniversityData(data);
    },
  });
};

const useUniversityMutationDelete = (onUniversityData) => {
  return useMutation(authService.deleteUniversity, {
    onSuccess: (data) => {
      onUniversityData(data);
    },
  });
};

const usePnpMutationList = (onPnpData) => {
  return useMutation(authService.pnp, {
    onSuccess: (data) => {
      onPnpData(data);
    },
  });
};

const usePnpMutationView = (onPnpData) => {
  return useMutation(authService.viewPnp, {
    onSuccess: (data) => {
      onPnpData(data);
    },
  });
};

const usePnpMutationAdd = (onPnpData) => {
  return useMutation(authService.addPnp, {
    onSuccess: (data) => {
      onPnpData(data);
    },
  });
};

const usePnpMutationEdit = (onPnpData) => {
  return useMutation(authService.editPnp, {
    onSuccess: (data) => {
      onPnpData(data);
    },
  });
};

const usePnpMutationDelete = (onPnpData) => {
  return useMutation(authService.deletePnp, {
    onSuccess: (data) => {
      onPnpData(data);
    },
  });
};

const useRefferalMutationList = (onRefferalData) => {
  return useMutation(authService.refferal, {
    onSuccess: (data) => {
      onRefferalData(data);
    },
  });
};

const useRefferalMutationView = (onRefferalData) => {
  return useMutation(authService.viewRefferal, {
    onSuccess: (data) => {
      onRefferalData(data);
    },
  });
};

const useRefferalMutationAdd = (onRefferalData) => {
  return useMutation(authService.addRefferal, {
    onSuccess: (data) => {
      onRefferalData(data);
    },
  });
};

const useRefferalMutationEdit = (onRefferalData) => {
  return useMutation(authService.editRefferal, {
    onSuccess: (data) => {
      onRefferalData(data);
    },
  });
};

const useRefferalMutationDelete = (onRefferalData) => {
  return useMutation(authService.deleteRefferal, {
    onSuccess: (data) => {
      onRefferalData(data);
    },
  });
};

const useIntakeMutationList = (onIntakeData) => {
  return useMutation(authService.intake, {
    onSuccess: (data) => {
      onIntakeData(data);
    },
  });
};

const useIntakeVisaMutationList = (onIntakeData) => {
  return useMutation(authService.intakeVisa, {
    onSuccess: (data) => {
      onIntakeData(data);
    },
  });
};

const useIntakeMutationView = (onIntakeData) => {
  return useMutation(authService.viewIntake, {
    onSuccess: (data) => {
      onIntakeData(data);
    },
  });
};

const useIntakeMutationAdd = (onIntakeData) => {
  return useMutation(authService.addIntake, {
    onSuccess: (data) => {
      onIntakeData(data);
    },
  });
};

const useIntakeMutationEdit = (onIntakeData) => {
  return useMutation(authService.editIntake, {
    onSuccess: (data) => {
      onIntakeData(data);
    },
  });
};

const useIntakeMutationDelete = (onIntakeData) => {
  return useMutation(authService.deleteIntake, {
    onSuccess: (data) => {
      onIntakeData(data);
    },
  });
};

const useUserDetailsMutationList = (onUserDetailsData) => {
  return useMutation(authService.userdetails, {
    onSuccess: (data) => {
      onUserDetailsData(data);
    },
  });
};

const useUserDetailsMutationView = (onUserDetailsData) => {
  return useMutation(authService.viewUserDetails, {
    onSuccess: (data) => {
      onUserDetailsData(data);
    },
  });
};

const useUserDetailsMutationAdd = (onUserDetailsData) => {
  return useMutation(authService.addUserDetails, {
    onSuccess: (data) => {
      onUserDetailsData(data);
    },
  });
};

const useUserDetailsMutationEdit = (onUserDetailsData) => {
  return useMutation(authService.editUserDetails, {
    onSuccess: (data) => {
      onUserDetailsData(data);
    },
  });
};

const useUserDetailsMutationDelete = (onUserDetailsData) => {
  return useMutation(authService.deleteUserDetails, {
    onSuccess: (data) => {
      onUserDetailsData(data);
    },
  });
};

const useApplicantMutationAdd = (onApplicantData) => {
  return useMutation(authService.addApplicant, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantMutationEdit = (onApplicantData) => {
  return useMutation(authService.editApplicant, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantMutationView = (onApplicantData) => {
  return useMutation(authService.viewApplicant, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};


const useApplicantMutationDelete = (onApplicantData) => {
  return useMutation(authService.deletApplicant, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantMutationBranchManagerList = (onApplicantData) => {
  return useMutation(authService.listApplicantBranchManager, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};


const useApplicantMutationRMList = (onApplicantData) => {
  return useMutation(authService.listApplicantRM, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantMutationVisaManagerList = (onApplicantData) => {
  return useMutation(authService.listApplicantVisaManager, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantAssignMutationList = (onApplicantData) => {
  return useMutation(authService.listApplicantAssingn, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantAssignProgramMutationList = (onApplicantData) => {
  return useMutation(authService.listApplicantAssingnProgram, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantAssignAdmissionMutationList = (onApplicantData) => {
  return useMutation(authService.listApplicantAssingnAdmission, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantAssignVisaStaffMutationList = (onApplicantData) => {
  return useMutation(authService.listApplicantAssingnVisaStaff, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantAssignDocumentStaffMutationList = (onApplicantData) => {
  return useMutation(authService.listApplicantAssingnDocumentStaff, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};

const useApplicantAssignSopMutationList = (onApplicantData) => {
  return useMutation(authService.listApplicantAssingnSop, {
    onSuccess: (data) => {
      onApplicantData(data);
    },
  });
};



const useTelecallerMutationList = (onTelecallerData) => {
  return useMutation(authService.listTelecaller, {
    onSuccess: (data) => {
      onTelecallerData(data);
    },
  });
};


const useAssociatesMutationList = (onTelecallerData) => {
  return useMutation(authService.listAssociates, {
    onSuccess: (data) => {
      onTelecallerData(data);
    },
  });
};


const useEducationMutationList = (onEducationData) => {
  return useMutation(authService.listEducational, {
    onSuccess: (data) => {
      onEducationData(data);
    },
  });
};

const useEducationMutationView = (onEducationData) => {
  return useMutation(authService.viewEducational, {
    onSuccess: (data) => {
      onEducationData(data);
    },
  });
};


const useEducationMutationAdd = (onEducationData) => {
  return useMutation(authService.addEducational, {
    onSuccess: (data) => {
      onEducationData(data);
    },
  });
};

const useEducationMutationDelete = (onEducationData) => {
  return useMutation(authService.deleteEducational, {
    onSuccess: (data) => {
      onEducationData(data);
    },
  });
};

const useEducationMutationEdit = (onEducationData) => {
  return useMutation(authService.editEducational, {
    onSuccess: (data) => {
      onEducationData(data);
    },
  });
};

const useInfoMutationList = (onInfoData) => {
  return useMutation(authService.listInform, {
    onSuccess: (data) => {
      onInfoData(data);
    },
  });
};


const useInfoMutationView = (onInfoData) => {
  return useMutation(authService.viewInform, {
    onSuccess: (data) => {
      onInfoData(data);
    },
  });
};

const useInfoMutationAdd = (onInfoData) => {
  return useMutation(authService.addInform, {
    onSuccess: (data) => {
      onInfoData(data);
    },
  });
};

const useInfoMutationEdit = (onInfoData) => {
  return useMutation(authService.editInform, {
    onSuccess: (data) => {
      onInfoData(data);
    },
  });
};

const useInfoMutationDelete = (onInfoData) => {
  return useMutation(authService.deleteInform, {
    onSuccess: (data) => {
      onInfoData(data);
    },
  });
};

const useWorkMutationList = (onWorkExpData) => {
  return useMutation(authService.listWorkExp, {
    onSuccess: (data) => {
      onWorkExpData(data);
    },
  });
};

const useWorkMutationView = (onWorkExpData) => {
  return useMutation(authService.viewWorkExp, {
    onSuccess: (data) => {
      onWorkExpData(data);
    },
  });
};


const useWorkMutationAdd = (onWorkExpData) => {
  return useMutation(authService.addWorkExp, {
    onSuccess: (data) => {
      onWorkExpData(data);
    },
  });
};

const useWorkMutationDelete = (onWorkExpData) => {
  return useMutation(authService.deleteWorkExp, {
    onSuccess: (data) => {
      onWorkExpData(data);
    },
  });
};

const useWorkMutationEdit = (onWorkExpData) => {
  return useMutation(authService.editWorkExp, {
    onSuccess: (data) => {
      onWorkExpData(data);
    },
  });
};

const useAddOtherDetailsCreate = (onOtherDetData) => {
  return useMutation(authService.addOtherDetails, {
    onSuccess: (data) => {
      onOtherDetData(data);
    },
  });
};

const useAddOtherDetailsEdit = (onOtherDetData) => {
  return useMutation(authService.editOtherDetails, {
    onSuccess: (data) => {
      onOtherDetData(data);
    },
  });
};

const useAddOtherDetailsList = (onOtherDetData) => {
  return useMutation(authService.listOtherDetails, {
    onSuccess: (data) => {
      onOtherDetData(data);
    },
  });
};


const useAddOtherDetailsView = (onOtherDetData) => {
  return useMutation(authService.viewOtherDetails, {
    onSuccess: (data) => {
      onOtherDetData(data);
    },
  });
};


const useIntProgramAdd = (onIntProData) => {
  return useMutation(authService.addIntPro, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useIntProgramList = (onIntProData) => {
  return useMutation(authService.listIntPro, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useIntProgramEdit = (onIntProData) => {
  return useMutation(authService.editIntPro, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useIntProgramDelete = (onIntProData) => {
  return useMutation(authService.deleteIntPro, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useIntProgramView = (onIntProData) => {
  return useMutation(authService.viewIntPro, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useMailSendProgramMutation = (onData) => {
  return useMutation(authService.mailSendProgram, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNotesUpdateMutation = (onIntProData) => {
  return useMutation(authService.updateNotes, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useIntProgramConfirm = (onIntProData) => {
  return useMutation(authService.programConfirm, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useIntProgramDragOrderMutation = (onIntProData) => {
  return useMutation(authService.dragOrder, {
    onSuccess: (data) => {
      onIntProData(data);
    },
  });
};

const useAcademicAdd = (onAcademicData) => {
  return useMutation(authService.addAcGap, {
    onSuccess: (data) => {
      onAcademicData(data);
    },
  });
};

const useAcademicEdit = (onAcademicData) => {
  return useMutation(authService.editAcGap, {
    onSuccess: (data) => {
      onAcademicData(data);
    },
  });
};

const useAcademicList = (onAcademicData) => {
  return useMutation(authService.listAcGap, {
    onSuccess: (data) => {
      onAcademicData(data);
    },
  });
};

const useAcademicView = (onAcademicData) => {
  return useMutation(authService.viewAcGap, {
    onSuccess: (data) => {
      onAcademicData(data);
    },
  });
};

const useAcademicDelete = (onAcademicData) => {
  return useMutation(authService.deleteAcGap, {
    onSuccess: (data) => {
      onAcademicData(data);
    },
  });
};


const useTravelHistoryAdd = (onTravelData) => {
  return useMutation(authService.addTravelHistory, {
    onSuccess: (data) => {
      onTravelData(data);
    },
  });
};

const useTravelHistoryEdit = (onTravelData) => {
  return useMutation(authService.editTravelHistory, {
    onSuccess: (data) => {
      onTravelData(data);
    },
  });
};

const useTravelHistoryList = (onTravelData) => {
  return useMutation(authService.listTravelHistory, {
    onSuccess: (data) => {
      onTravelData(data);
    },
  });
};

const useTravelHistoryView = (onTravelData) => {
  return useMutation(authService.viewTravelHistory, {
    onSuccess: (data) => {
      onTravelData(data);
    },
  });
};

const useTravelHistoryDelete = (onTravelData) => {
  return useMutation(authService.deleteTravelHistory, {
    onSuccess: (data) => {
      onTravelData(data);
    },
  });
};


const useServicesSoughtAdd = (onServiceData) => {
  return useMutation(authService.addServiceSought, {
    onSuccess: (data) => {
      onServiceData(data);
    },
  });
};

const useServicesSoughtEdit = (onServiceData) => {
  return useMutation(authService.editServiceSought, {
    onSuccess: (data) => {
      onServiceData(data);
    },
  });
};

const useServicesSoughtList = (onServiceData) => {
  return useMutation(authService.listServiceSought, {
    onSuccess: (data) => {
      onServiceData(data);
    },
  });
};

const useServicesSoughtView = (onServiceData) => {
  return useMutation(authService.viewServiceSought, {
    onSuccess: (data) => {
      onServiceData(data);
    },
  });
};

const useServicesSoughtDelete = (onServiceData) => {
  return useMutation(authService.deleteServiceSought, {
    onSuccess: (data) => {
      onServiceData(data);
    },
  });
};

const useAppointmentMutationList = (onAppoData) => {
  return useMutation(authService.listAppointment, {
    onSuccess: (data) => {
      onAppoData(data);
    },
  });
};

const useAppointmentMutationView = (onAppoData) => {
  return useMutation(authService.viewAppointment, {
    onSuccess: (data) => {
      onAppoData(data);
    },
  });
};

const useAppointmentMutationAdd = (onAppoData) => {
  return useMutation(authService.addAppointment, {
    onSuccess: (data) => {
      onAppoData(data);
    },
  });
};

const useAppointmentMutationEdit = (onAppoData) => {
  return useMutation(authService.editAppointment, {
    onSuccess: (data) => {
      onAppoData(data);
    },
  });
};

const useAppointmentMutationDelete = (onAppoData) => {
  return useMutation(authService.deleteAppointment, {
    onSuccess: (data) => {
      onAppoData(data);
    },
  });
};

const useDocDetailMutationList = (onDocData) => {
  return useMutation(authService.listDoc, {
    onSuccess: (data) => {
      onDocData(data);
    },
  });
};

const useDocDetailMutationView = (onDocData) => {
  return useMutation(authService.viewDoc, {
    onSuccess: (data) => {
      onDocData(data);
    },
  });
};

const useDocDetailMutationAdd = (onDocData) => {
  return useMutation(authService.addDoc, {
    onSuccess: (data) => {
      onDocData(data);
    },
  });
};

const useDocDetailMutationEdit = (onDocData) => {
  return useMutation(authService.editDoc, {
    onSuccess: (data) => {
      onDocData(data);
    },
  });
};

const useDocDetailMutationDelete = (onDocData) => {
  return useMutation(authService.deleteDoc, {
    onSuccess: (data) => {
      onDocData(data);
    },
  });
};

const useAppointClientAdd = (onAppointClientData) => {
  return useMutation(authService.addAppointClient, {
    onSuccess: (data) => {
      onAppointClientData(data);
    },
  });
};

const useAppointClientList = (onAppointClientData) => {
  return useMutation(authService.listAppointClient, {
    onSuccess: (data) => {
      onAppointClientData(data);
    },
  });
};

const useFollowupHistoryList = (onData) => {
  return useMutation(authService.listFollowupHistory, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAppointClientEdit = (onAppointClientData) => {
  return useMutation(authService.editAppointClient, {
    onSuccess: (data) => {
      onAppointClientData(data);
    },
  });
};

const useAppointClientDelete = (onAppointClientData) => {
  return useMutation(authService.deleteAppointClient, {
    onSuccess: (data) => {
      onAppointClientData(data);
    },
  });
};

const useAppointClientView = (onAppointClientData) => {
  return useMutation(authService.viewAppointClient, {
    onSuccess: (data) => {
      onAppointClientData(data);
    },
  });
};

const useFollowupCommentCreateMutation = (onAppointClientData) => {
  return useMutation(authService.followupCommentAdd, {
    onSuccess: (data) => {
      onAppointClientData(data);
    },
  });
};

const useCounselorDashboardConfirmMutation = (onData) => {
  return useMutation(authService.followupConfirm, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useOverseasAdd = (onOverseasData) => {
  return useMutation(authService.addOverseas, {
    onSuccess: (data) => {
      onOverseasData(data);
    },
  });
};

const useOverseasList = (onOverseasData) => {
  return useMutation(authService.listOverseas, {
    onSuccess: (data) => {
      onOverseasData(data);
    },
  });
};

const useOverseasEdit = (onOverseasData) => {
  return useMutation(authService.editOverseas, {
    onSuccess: (data) => {
      onOverseasData(data);
    },
  });
};

const useOverseasDelete = (onOverseasData) => {
  return useMutation(authService.deleteOverseas, {
    onSuccess: (data) => {
      onOverseasData(data);
    },
  });
};

const useOverseasView = (onOverseasData) => {
  return useMutation(authService.viewOverseas, {
    onSuccess: (data) => {
      onOverseasData(data);
    },
  });
};

const useAssignAdd = (onAssignData) => {
  return useMutation(authService.addAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useAssignList = (onAssignData) => {
  return useMutation(authService.listAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useAssignRoleView = (onAssignData) => {
  return useMutation(authService.viewRoleAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};


const useAssignEdit = (onAssignData) => {
  return useMutation(authService.editAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useAssignDelete = (onAssignData) => {
  return useMutation(authService.deleteAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useAssignView = (onAssignData) => {
  return useMutation(authService.viewAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useCounselorAssignList = (onAssignData) => {
  return useMutation(authService.listCounselorAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useTelecallerAssignMutation = (onAssignData) => {
  return useMutation(authService.telecallerAssign, {
    onSuccess: (data) => {
      onAssignData(data);
    },
  });
};

const useProspectAdd = (onProspectData) => {
  return useMutation(authService.addProspect, {
    onSuccess: (data) => {
      onProspectData(data);
    },
  });
};

const useProspectList = (onProspectData) => {
  return useMutation(authService.listProspect, {
    onSuccess: (data) => {
      onProspectData(data);
    },
  });
};

const useProspectEdit = (onProspectData) => {
  return useMutation(authService.editProspect, {
    onSuccess: (data) => {
      onProspectData(data);
    },
  });
};

const useProspectDelete = (onProspectData) => {
  return useMutation(authService.deleteProspect, {
    onSuccess: (data) => {
      onProspectData(data);
    },
  });
};

const useStatusOfProspectsRoleList = (onProspectData) => {
  return useMutation(authService.roleWiseStatusOfProspect, {
    onSuccess: (data) => {
      onProspectData(data);
    },
  });
};

const useProspectView = (onProspectData) => {
  return useMutation(authService.viewProspect, {
    onSuccess: (data) => {
      onProspectData(data);
    },
  });
};

const usePGDMutationList = (onPGDData) => {
  return useMutation(authService.listDuration, {
    onSuccess: (data) => {
      onPGDData(data);
    },
  });
};

const usePGDMutationView = (onPGDData) => {
  return useMutation(authService.viewDuration, {
    onSuccess: (data) => {
      onPGDData(data);
    },
  });
};

const usePGDMutationAdd = (onPGDData) => {
  return useMutation(authService.addDuration, {
    onSuccess: (data) => {
      onPGDData(data);
    },
  });
};

const usePGDMutationEdit = (onPGDData) => {
  return useMutation(authService.editDuration, {
    onSuccess: (data) => {
      onPGDData(data);
    },
  });
};

const usePGDMutationDelete = (onPGDData) => {
  return useMutation(authService.deleteDuration, {
    onSuccess: (data) => {
      onPGDData(data);
    },
  });
};

const useFLDList = (onFLDData) => {
  return useMutation(authService.listFollowDuration, {
    onSuccess: (data) => {
      onFLDData(data);
    },
  });
};

const useFLDView = (onPGDData) => {
  return useMutation(authService.viewFollowDuration, {
    onSuccess: (data) => {
      onPGDData(data);
    },
  });
};

const useFLDAdd = (onFLDData) => {
  return useMutation(authService.addFollowDuration, {
    onSuccess: (data) => {
      onFLDData(data);
    },
  });
};

const useFLDEdit = (onFLDData) => {
  return useMutation(authService.editFollowDuration, {
    onSuccess: (data) => {
      onFLDData(data);
    },
  });
};

const useFLDDelete = (onFLDData) => {
  return useMutation(authService.deleteFollowDuration, {
    onSuccess: (data) => {
      onFLDData(data);
    },
  });
};

const useProStatusAdd = (onProStatusData) => {
  return useMutation(authService.addStatusPro, {
    onSuccess: (data) => {
      onProStatusData(data);
    },
  });
};

const useProStatusList = (onProStatusData) => {
  return useMutation(authService.listStatusPro, {
    onSuccess: (data) => {
      onProStatusData(data);
    },
  });
};

const useProStatusEdit = (onProStatusData) => {
  return useMutation(authService.editStatusPro, {
    onSuccess: (data) => {
      onProStatusData(data);
    },
  });
};

const useProStatusDelete = (onProStatusData) => {
  return useMutation(authService.deleteStatusPro, {
    onSuccess: (data) => {
      onProStatusData(data);
    },
  });
};

const useProStatusView = (onProStatusData) => {
  return useMutation(authService.viewStatusPro, {
    onSuccess: (data) => {
      onProStatusData(data);
    },
  });
};

const useDocumentAdd = (onDocumentData) => {
  return useMutation(authService.addDocument, {
    onSuccess: (data) => {
      onDocumentData(data);
    },
  });
};

const useDocumentEdit = (onDocumentData) => {
  return useMutation(authService.editDocument, {
    onSuccess: (data) => {
      onDocumentData(data);
    },
  });
};

const useDocumentList = (onDocumentData) => {
  return useMutation(authService.listDocument, {
    onSuccess: (data) => {
      onDocumentData(data);
    },
  });
};

const useDocumentView = (onDocumentData) => {
  return useMutation(authService.viewDocument, {
    onSuccess: (data) => {
      onDocumentData(data);
    },
  });
};

const usePaymentAdd = (onPaymentData) => {
  return useMutation(authService.addPayment, {
    onSuccess: (data) => {
      onPaymentData(data);
    },
  });
};

const usePaymentList = (onPaymentData) => {
  return useMutation(authService.listPayment, {
    onSuccess: (data) => {
      onPaymentData(data);
    },
  });
};

const usePaymentEdit = (onPaymentData) => {
  return useMutation(authService.editPayment, {
    onSuccess: (data) => {
      onPaymentData(data);
    },
  });
};

const usePaymentDelete = (onPaymentData) => {
  return useMutation(authService.deletePayment, {
    onSuccess: (data) => {
      onPaymentData(data);
    },
  });
};

const usePaymentView = (onPaymentData) => {
  return useMutation(authService.viewPayment, {
    onSuccess: (data) => {
      onPaymentData(data);
    },
  });
};

const useFeeAdd = (onFeeData) => {
  return useMutation(authService.addFee, {
    onSuccess: (data) => {
      onFeeData(data);
    },
  });
};
const useFeeEdit = (onFeeData) => {
  return useMutation(authService.editFee, {
    onSuccess: (data) => {
      onFeeData(data);
    },
  });
};
const useFeeView = (onFeeData) => {
  return useMutation(authService.viewFee, {
    onSuccess: (data) => {
      onFeeData(data);
    },
  });
};

const useCommentList = (commentData) => {
  return useMutation(authService.listComment, {
    onSuccess: (data) => {
      commentData(data);
    },
  });
};

const useEnglishProAdd = (englishData) => {
  return useMutation(authService.addEnglishPro, {
    onSuccess: (data) => {
      englishData(data);
    },
  });
};

const useEnglishProList = (englishData) => {
  return useMutation(authService.listEnglishPro, {
    onSuccess: (data) => {
      englishData(data);
    },
  });
};

const useEnglishProDelete = (englishData) => {
  return useMutation(authService.deleteEnglishPro, {
    onSuccess: (data) => {
      englishData(data);
    },
  });
};

const useEnglishProEdit = (englishData) => {
  return useMutation(authService.editEnglishPro, {
    onSuccess: (data) => {
      englishData(data);
    },
  });
};

const useProgressChange = (progressData) => {
  return useMutation(authService.progressChange, {
    onSuccess: (data) => {
      progressData(data);
    },
  });
};

const useEmailValidApplicant = (validData) => {
  return useMutation(authService.emailValidationApplicant, {
    onSuccess: (data) => {
      validData(data);
    },
  });
};

const useMobileValidApplicant = (validData) => {
  return useMutation(authService.mobileValidationApplicant, {
    onSuccess: (data) => {
      validData(data);
    },
  });
};

const useFileUploadCommon = (fileData) => {
  return useMutation(authService.fileUploadCommon, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

const usePdfUploadCommon = (pdfData) => {
  return useMutation(authService.pdfUploadCommon, {
    onSuccess: (data) => {
      pdfData(data);
    },
  });
};

const usePdfMergeCommon = (pdfData) => {
  return useMutation(authService.pdfMergeCommon, {
    onSuccess: (data) => {
      pdfData(data);
    },
  });
};

const usePdfDeleteCommon = (pdfData) => {
  return useMutation(authService.pdfDeleteCommon, {
    onSuccess: (data) => {
      pdfData(data);
    },
  });
};

const useExcelUploadMutationCommon = (onData) => {
  return useMutation(authService.excelUploadCommon, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicantFileAdd = (fileData) => {
  return useMutation(authService.applicantFileUploadAdd, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

const useApplicantFileEdit = (fileData) => {
  return useMutation(authService.applicantFileUploadEdit, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

const useApplicantFileList = (fileData) => {
  return useMutation(authService.applicantFileUploadList, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

const useApplicantFileView = (fileData) => {
  return useMutation(authService.applicantFileUploadView, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

const useApplicantFileDelete = (fileData) => {
  return useMutation(authService.applicantFileUploadDelete, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

const useUserRoleMutationList = (onUserRoleData) => {
  return useMutation(authService.userRoleList, {
    onSuccess: (data) => {
      onUserRoleData(data);
    },
  });
};

const useUserFollowMutationList = (onUserRoleData) => {
  return useMutation(authService.userFollowList, {
    onSuccess: (data) => {
      onUserRoleData(data);
    },
  });
};

const useUserRoleMutationView = (onUserRoleData) => {
  return useMutation(authService.userRoleView, {
    onSuccess: (data) => {
      onUserRoleData(data);
    },
  });
};

const useUserRoleMutationEdit = (onUserRoleData) => {
  return useMutation(authService.userRoleEdit, {
    onSuccess: (data) => {
      onUserRoleData(data);
    },
  });
};

const useUserRoleMutationDelete = (onUserRoleData) => {
  return useMutation(authService.userRoleDelete, {
    onSuccess: (data) => {
      onUserRoleData(data);
    },
  });
};

const useCurrencyMutationList = (onCurrencyData) => {
  return useMutation(authService.currencyList, {
    onSuccess: (data) => {
      onCurrencyData(data);
    },
  });
};

const useAdmissionProgramWiseMutationList = (onAdmission) => {
  return useMutation(authService.admissionProgramWiseListing, {
    onSuccess: (data) => {
      onAdmission(data);
    },
  });
};

const useAdmissionRegisterCreate = (onAdmissionRegister) => {
  return useMutation(authService.admissionRegisterCreate, {
    onSuccess: (data) => {
      onAdmissionRegister(data);
    },
  });
};

const useAdmissionRegisterMutateUpdate = (onAdmissionRegister) => {
  return useMutation(authService.admissionRegisterUpdate, {
    onSuccess: (data) => {
      onAdmissionRegister(data);
    },
  });
};

const useAdmissionFeeTypeCreateMutation = (onAdmissionRegister) => {
  return useMutation(authService.admissionFeeTypeCreate, {
    onSuccess: (data) => {
      onAdmissionRegister(data);
    },
  });
};

const useAdmissionFeeTypeUpdateMutation = (onAdmissionRegister) => {
  return useMutation(authService.admissionFeeTypeUpdate, {
    onSuccess: (data) => {
      onAdmissionRegister(data);
    },
  });
};



const useFeeCoordinatorMutationList = (onFee) => {
  return useMutation(authService.feeCoordinatorListing, {
    onSuccess: (data) => {
      onFee(data);
    },
  });
};

const useFeeCoordinatorMutationModalList = (onFee) => {
  return useMutation(authService.feeCoordinatorModalListing, {
    onSuccess: (data) => {
      onFee(data);
    },
  });
};

const useFeeCoordinatorMutationModalCreate = (onFee) => {
  return useMutation(authService.feeCoordinatorModalPayment, {
    onSuccess: (data) => {
      onFee(data);
    },
  });
};

const useFeeCoordinatorMutationModalPaymentList = (onFee) => {
  return useMutation(authService.feeCoordinatorModalPaymentList, {
    onSuccess: (data) => {
      onFee(data);
    },
  });
};

const useVisaDataMutationCreate = (onVisa) => {
  return useMutation(authService.visaDataCreate, {
    onSuccess: (data) => {
      onVisa(data);
    },
  });
};

const useApplicationMutationViewAll = (onData) => {
  return useMutation(authService.applicantViewAllProfile, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicationMutationMarkRegistration = (onData) => {
  return useMutation(authService.markRegistration, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useExcelImportMutationCreate = (onData) => {
  return useMutation(authService.importFile, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useImportApplicantMutationList = (onData) => {
  return useMutation(authService.importList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useImportApplicantMutationDelete = (onData) => {
  return useMutation(authService.importDelete, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorReport= (onCounselorReportData) => {
  return useMutation(authService.counselorReport, {
    onSuccess: (data) => {
      onCounselorReportData(data);
    },
  });
};

const useClientList = (onClientData) => {
  return useMutation(authService.clientList, {
    onSuccess: (data) => {
      onClientData(data);
    },
  });
};


const useCampusMutationList = (onCampusData) => {
  return useMutation(authService.listCampus, {
    onSuccess: (data) => {
      onCampusData(data);
    },
  });
};

const useCampusMutationAllList = (onCampusData) => {
  return useMutation(authService.alllistCampus, {
    onSuccess: (data) => {
      onCampusData(data);
    },
  });
};

const useCampusMutationView = (onCampusData) => {
  return useMutation(authService.viewCampus, {
    onSuccess: (data) => {
      onCampusData(data);
    },
  });
};

const useCampusMutationAdd = (onCampusData) => {
  return useMutation(authService.addCampus, {
    onSuccess: (data) => {
      onCampusData(data);
    },
  });
};

const useCampusMutationEdit = (onCampusData) => {
  return useMutation(authService.editCampus, {
    onSuccess: (data) => {
      onCampusData(data);
    },
  });
};

const useCampusMutationDelete = (onCampusData) => {
  return useMutation(authService.deleteCampus, {
    onSuccess: (data) => {
      onCampusData(data);
    },
  });
};


const useCounselorDashboardCountMutation = (onData) => {
  return useMutation(authService.counselorDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardFeePaidMutation = (onData) => {
  return useMutation(authService.counselorDashboardFeePaid, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAssociatesDashboardFeePaidMutation = (onData) => {
  return useMutation(authService.associatesDashboardFeePaid, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardVisaMutation = (onData) => {
  return useMutation(authService.counselorDashboardVisa, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardRegMutation = (onData) => {
  return useMutation(authService.counselorDashboardReg, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardFollowupMutation = (onData) => {
  return useMutation(authService.counselorDashboardFollowup, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardPendingMutation = (onData) => {
  return useMutation(authService.counselorDashboardPending, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardAutoFollowupMutation = (onData) => {
  return useMutation(authService.counselorDashboardAutoFollowup, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardAllPendingMutation = (onData) => {
  return useMutation(authService.counselorDashboardAllPending, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardTotalMutation = (onData) => {
  return useMutation(authService.counselorDashboardTotal, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardActiveMutation = (onData) => {
  return useMutation(authService.counselorDashboardActive, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useBranchManagerDashboardActiveMutation = (onData) => {
  return useMutation(authService.branchManagerDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useRegionalManagerDashboardCount = (onData) => {
  return useMutation(authService.regionalManagerCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useSeniorProgramManagerDashboardActiveMutation = (onData) => {
  return useMutation(authService.seniorProgramManagerCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUniqueStatusReport= (onUniqueReportData) => {
  return useMutation(authService.uniquestatusReport, {
    onSuccess: (data) => {
      onUniqueReportData(data);
    },
  });
}

const useTelecallerDashboardCount = (onData) => {
  return useMutation(authService.telecallerDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTargetOfficeMutationList = (onData) => {
  return useMutation(authService.targetOfficeList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTargetMutationView = (onData) => {
  return useMutation(authService.targetView, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTargetMutationAdd = (onData) => {
  return useMutation(authService.targetAdd, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTargetTotalCountView = (onData) => {
  return useMutation(authService.targetCountView, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTelecallerGraph = (onData) => {
  return useMutation(authService.telecallerDashboard, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardMutationTargetCount = (onData) => {
  return useMutation(authService.counselorDashboardTarget, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorFeePaidMutationDelete = (onData) => {
  return useMutation(authService.admissionFeePaidDelete, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAppStaffStatusMutationList = (onAppStatusData) => {
  return useMutation(authService.listAppStaffStatus, {
    onSuccess: (data) => {
      onAppStatusData(data);
    },
  });
};

const useAppStaffStatusMutationView = (onAppStatusData) => {
  return useMutation(authService.viewAppStaffStatus, {
    onSuccess: (data) => {
      onAppStatusData(data);
    },
  });
};

const useAppStaffStatusMutationAdd = (onAppStatusData) => {
  return useMutation(authService.addAppStaffStatus, {
    onSuccess: (data) => {
      onAppStatusData(data);
    },
  });
};

const useAppStaffStatusMutationEdit = (onAppStatusData) => {
  return useMutation(authService.editAppStaffStatus, {
    onSuccess: (data) => {
      onAppStatusData(data);
    },
  });
};

const useAppStaffStatusMutationDelete = (onAppStatusData) => {
  return useMutation(authService.deleteAppStaffStatus, {
    onSuccess: (data) => {
      onAppStatusData(data);
    },
  });
};

const useEmployeeReport= (onEmployeeReportData) => {
  return useMutation(authService.employeeReport, {
    onSuccess: (data) => {
      onEmployeeReportData(data);
    },
  });
};

const useCounselorRegistrationReport= (onCounselorRegistrationData) => {
  return useMutation(authService.counselorRegistration, {
    onSuccess: (data) => {
      onCounselorRegistrationData(data);
    },
  });
};

const useCounselorRegistrationCountryReport= (onCounselorRegistrationCountryData) => {
  return useMutation(authService.counselorCountryRegistration, {
    onSuccess: (data) => {
      onCounselorRegistrationCountryData(data);
    },
  });
};

const useFeePaidTypeMutationList = (onFeePaidData) => {
  return useMutation(authService.listFeePaidType, {
    onSuccess: (data) => {
      onFeePaidData(data);
    },
  });
};

const useFeePaidTypeMutationView = (onFeePaidData) => {
  return useMutation(authService.viewFeePaidType, {
    onSuccess: (data) => {
      onFeePaidData(data);
    },
  });
};

const useFeePaidTypeMutationAdd = (onFeePaidData) => {
  return useMutation(authService.addFeePaidType, {
    onSuccess: (data) => {
      onFeePaidData(data);
    },
  });
};

const useFeePaidTypeMutationEdit = (onFeePaidData) => {
  return useMutation(authService.editFeePaidType, {
    onSuccess: (data) => {
      onFeePaidData(data);
    },
  });
};

const useFeePaidTypeMutationDelete = (onFeePaidData) => {
  return useMutation(authService.deleteFeePaidType, {
    onSuccess: (data) => {
      onFeePaidData(data);
    },
  });
};

const useLeadTypeMutationList = (onLeadTypeData) => {
  return useMutation(authService.listLeadType, {
    onSuccess: (data) => {
      onLeadTypeData(data);
    },
  });
};

const useLeadTypeMutationView = (onLeadTypeData) => {
  return useMutation(authService.viewLeadType, {
    onSuccess: (data) => {
      onLeadTypeData(data);
    },
  });
};

const useLeadTypeMutationAdd = (onLeadTypeData) => {
  return useMutation(authService.addLeadType, {
    onSuccess: (data) => {
      onLeadTypeData(data);
    },
  });
};

const useLeadTypeMutationEdit = (onLeadTypeData) => {
  return useMutation(authService.editLeadType, {
    onSuccess: (data) => {
      onLeadTypeData(data);
    },
  });
};

const useLeadTypeMutationDelete = (onLeadTypeData) => {
  return useMutation(authService.deleteLeadType, {
    onSuccess: (data) => {
      onLeadTypeData(data);
    },
  });
};

const useCounselorDashboardIELTSCountMutation = (onData) => {
  return useMutation(authService.counselorDashboardIELTSCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardIELTSPendingMutation = (onData) => {
  return useMutation(authService.counselorDashboardIELTSPendingList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardTOTCountMutation = (onData) => {
  return useMutation(authService.counselorDashboardTOTCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorDashboardTOTPendingMutation = (onData) => {
  return useMutation(authService.counselorDashboardTOTPendingList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAssoiciatesDashboardMutation = (onData) => {
  return useMutation(authService.associatesDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEODashboardMutation = (onData) => {
  return useMutation(authService.ceoDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEODashboardTotalMutation = (onData) => {
  return useMutation(authService.ceoDashboardTotalCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEODashboardCFYMutation = (onData) => {
  return useMutation(authService.ceoDashboardCFYCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEODashboardCountryMutation = (onData) => {
  return useMutation(authService.ceoDashboardCountryCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useManagerDashboardMutation = (onData) => {
  return useMutation(authService.managerDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useLeaderBoardCount = (onData) => {
  return useMutation(authService.leaderBoardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useCEOClientListMutation = (onData) => {
  return useMutation(authService.ceoClientList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useMarkRegisterPendingMutation = (onData) => {
  return useMutation(authService.listMarkRegisterPending, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useCountrywiseReport= (onCountrywiseReportData) => {
  return useMutation(authService.countrywise, {
    onSuccess: (data) => {
      onCountrywiseReportData(data);
    },
  });
};


const useUniqueCountrywiseReport= (onUniqueCountrywiseReportData) => {
  return useMutation(authService.uniquecountrywise, {
    onSuccess: (data) => {
      onUniqueCountrywiseReportData(data);
    },
  });
};
  

const useVisaStaffDashboardCountMutation = (onData) => {
  return useMutation(authService.visaStaffDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useProgramDashboardCountMutation = (onData) => {
  return useMutation(authService.programDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAdmissionDashboardCountMutation = (onData) => {
  return useMutation(authService.admissionStaffDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useFeeDashboardCountMutation = (onData) => {
  return useMutation(authService.feeCoordinatorDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEONotInterestedMutation = (onData) => {
  return useMutation(authService.notInterestReportCEO, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEONotQualifiedMutation = (onData) => {
  return useMutation(authService.notQualifiedReportCEO, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEOFeePaidMutation = (onData) => {
  return useMutation(authService.feePaidCEOReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCEOVisaApprovedMutation = (onData) => {
  return useMutation(authService.visaApprovedCEOReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicantList = (onData) => {
  return useMutation(authService.uniqueApplicantlist, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNotificationList = (onData) => {
  return useMutation(authService.notificiationList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNotificationReadMutation = (onData) => {
  return useMutation(authService.notificiationRead, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNotificationReadAllMutation = (onData) => {
  return useMutation(authService.notificiationReadAll, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVisaDataMutatuionCreate = (onData) => {
  return useMutation(authService.createVisaData, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVisaDataMutatuionList = (onData) => {
  return useMutation(authService.listVisaData, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVisaDataMutatuionView = (onData) => {
  return useMutation(authService.viewVisaData, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useEventCreateMutationCreate = (onData) => {
  return useMutation(authService.eventCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useEventCreateMutationList = (onData) => {
  return useMutation(authService.eventList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCountryApplicantList = (onData) => {
  return useMutation(authService.countryApplicantList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCeoDashboardReferenceCount = (onData) => {
  return useMutation(authService.ceoDashboardReference, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCeoDashboardBranchCount = (onData) => {
  return useMutation(authService.ceoDashboardBranch, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorWiseListing = (onData) => {
  return useMutation(authService.counselorWiseListing, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useBulkAssignMutation = (onData) => {
  return useMutation(authService.bulkAssign, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSearchAllApplicant = (onData) => {
  return useMutation(authService.searchApplicant, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useBranchwiseReport= (onData) => {
  return useMutation(authService.branchwiseReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useBranchTargeteReport= (onData) => {
  return useMutation(authService.branchTargetReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCountryTargeteReport= (onData) => {
  return useMutation(authService.countryTargetReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};
const useFeeStatusReport= (onData) => {
  return useMutation(authService.feeStatusReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useExecutiveApplicant = (onData) => {
  return useMutation(authService.executiveApplicant, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useExportPdf = (onData) => {
  return useMutation(authService.exportApplicant, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useReferenceReport = (onData) => {
  return useMutation(authService.referencewiseReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicationLoanMutationList = (onData) => {
  return useMutation(authService.applicationLoanList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicantExeConfirmMutation = (onData) => {
  return useMutation(authService.applicationLoanConfirm, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useMarkRegMutationCounts = (onData) => {
  return useMutation(authService.markRegCounts, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useGicAssignMutation = (onData) => {
  return useMutation(authService.gicAssign, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useProgramDocumentCreateMutation = (onData) => {
  return useMutation(authService.programDocCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useProgramDocumentListMutation = (onData) => {
  return useMutation(authService.programDocList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useProgramDocumentDeleteMutation = (onData) => {
  return useMutation(authService.programDocDelete, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useExportCEOAllApplicantMutation = (onData) => {
  return useMutation(authService.exportCeoAllApplicant, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useDailyFollowReportMutation = (onData) => {
  return useMutation(authService.dailyReportFollowup, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useDailyFollowReportCEOMutation = (onData) => {
  return useMutation(authService.dailyReportCEOFollowup, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorAssociatesMutation = (onData) => {
  return useMutation(authService.counselorAssociates, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAddNewCounselorAssociatesMutation = (onData) => {
  return useMutation(authService.addCounselorAssociates, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useDeleteCounselorAssociatesMutation = (onData) => {
  return useMutation(authService.deleteCounselorAssociates, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useOfficeCounselorAssociatesMutation = (onData) => {
  return useMutation(authService.officeCounselorAssociates, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useOfficeWiseCounselorListMutation = (onData) => {
  return useMutation(authService.officeWiseCounselorList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useOfficeWiseOnlyCounselorListMutation = (onData) => {
  return useMutation(authService.officeWiseOnlyCounselorList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useEnglishProfiencyStatusChangeMutation = (onData) => {
  return useMutation(authService.englishProfiencyStatus, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useAllNotInterestOrQualifiedListMutation = (onData) => {
  return useMutation(authService.allNotInterestOrQualifiedList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useReAssignCounselorMutation = (onData) => {
  return useMutation(authService.reAssignCounselor, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useCaptureApplicantTelecallerMutation = (onData) => {
  return useMutation(authService.captureApplicantTelecaller, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useApplicantProgramPublicListMutation = (onData) => {
  return useMutation(authService.applicantProgramPublicList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useApplicantProgramPublicUpdateMutation = (onData) => {
  return useMutation(authService.applicantProgramPublicUpdate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useProgramSuggestionListMutation = (onData) => {
  return useMutation(authService.programSuggestionList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useTopPerformListingMutation = (onData) => {
  return useMutation(authService.topPerformList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useAssociatesReportMutation = (onData) => {
  return useMutation(authService.associatesReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useAssociatesReportListMutation = (onData) => {
  return useMutation(authService.associatesReportListing, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFinishedClientsListMutation = (onData) => {
  return useMutation(authService.finishedClientsList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFinishedClientsVisaTypeListMutation = (onData) => {
  return useMutation(authService.finishedClientsVisaTypeList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFinishedClientsOfficeListMutation = (onData) => {
  return useMutation(authService.finishedClientsOfficeList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useVisaWiseStatusListingMutation = (onData) => {
  return useMutation(authService.visaWiseStatusListing, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useRoleWiseStatusListingMutation = (onData) => {
  return useMutation(authService.roleWiseStatusListing, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useVisaWiseStatusCreateMutation = (onData) => {
  return useMutation(authService.visaWiseStatusCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useRoleWiseStatusCreateMutation = (onData) => {
  return useMutation(authService.roleWiseStatusCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useAssociateTileListMutation = (onData) => {
  return useMutation(authService.associateTileList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useRoleWiseListingUsersMutation = (onData) => {
  return useMutation(authService.roleWiseUserList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFollowUpTodayOfficeWiseMutation = (onData) => {
  return useMutation(authService.followUpTodayOfficeWise, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFollowUpPendingOfficeWiseMutation = (onData) => {
  return useMutation(authService.followUpPendingOfficeWise, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFollowUpTodaySPMMutation = (onData) => {
  return useMutation(authService.followUpTodaySPM, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFollowUpPendingSPMMutation = (onData) => {
  return useMutation(authService.followUpPendingSPM, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useAdvancedSearchMutation = (onData) => {
  return useMutation(authService.advancedSearchMasters, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useApplicantFormMastersList = (onData) => {
  return useMutation(authService.applicantFormMaster, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useChangePasswordMutation = (onData) => {
  return useMutation(authService.changePassword, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useOldDocumentListing = (onData) => {
  return useMutation(authService.oldDocumentList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFollowupTodayBranchManagerCount = (onData) => {
  return useMutation(authService.oldDocumentList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFollowupPendingBranchManagerCount = (onData) => {
  return useMutation(authService.oldDocumentList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useBranchManagerFollowupDropDown = (onData) => {
  return useMutation(authService.bmFollowupDropDown, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useSeniorProgramManagerFollowupDropDown = (onData) => {
  return useMutation(authService.spmFollowupDropDown, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useCounselorApplicantList = (onData) => {
  return useMutation(authService.counselorapplicants, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}


const useSeniorManagerListingMaster = (onData) => {
  return useMutation(authService.seniorManagerListingMaster, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useSeniorManagerAddMaster = (onData) => {
  return useMutation(authService.seniorManagerAddMaster, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useSeniorManagerRemoveMaster = (onData) => {
  return useMutation(authService.seniorManagerRemoveMaster, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useSeniorManagerUsersList = (onData) => {
  return useMutation(authService.seniorManagerUsersList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFairApplicationCreate = (onData) => {
  return useMutation(authService.fairApplicationCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFairApplicationList = (onData) => {
  return useMutation(authService.fairApplicationList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useFairApplicationEdit = (onData) => {
  return useMutation(authService.fairApplicationEdit, {
    onSuccess: (data) => {
      onData(data);
    },
  });
}

const useApplicationSPMMyclientListMutation = (onData) => {
  return useMutation(authService.listSPMMyclientList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicationAssignedSPCMyclientListMutation = (onData) => {
  return useMutation(authService.listAssignedSPCMyclientList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useExportFairStaffApplication = (onData) => {
  return useMutation(authService.exportFairApplication, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useProgramSuggestionMasterList = (onData) => {
  return useMutation(authService.programSuggestionMaster, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSopStaffDashboardCounts = (onData) => {
  return useMutation(authService.sopStaffDashboardCount, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicantAssignedUsersDetails = (onData) => {
  return useMutation(authService.userdetailsAssigned, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorCountryApplicantlist = (onData) => {
  return useMutation(authService.counselorCountryApplicantlist, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useProgramPendingList = (onData) => {
  return useMutation(authService.programPendingList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useChangeLeadStatus = (onData) => {
  return useMutation(authService.changeLeadStatus, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAdmissionstaffReport= (onAdmissionstaff) => {
  return useMutation(authService.admissionstaffreport, {
    onSuccess: (data) => {
      onAdmissionstaff(data);
    },
  });
};


const usePgmsuggestionReport= (onPgmsuggestion) => {
  return useMutation(authService.pgmsuggestionreport, {
    onSuccess: (data) => {
      onPgmsuggestion(data);
    },
  });
};

const usePgmsuggestionReportMaster= (onPgmsuggestionMaster) => {
  return useMutation(authService.programSuggestionReportMaster, {
    onSuccess: (data) => {
      onPgmsuggestionMaster(data);
    },
  });
};

const usePgmsuggestionIntakeMaster= (onPgmsuggestionintakeMaster) => {
  return useMutation(authService.programSuggestionIntakeMaster, {
    onSuccess: (data) => {
      onPgmsuggestionintakeMaster(data);
    },
  });
};

const useCounselorNotinterestedReport= (onCounselorNISData) => {
  return useMutation(authService.counselorNIS, {
    onSuccess: (data) => {
      onCounselorNISData(data);
    },
  });
};

const useCountryTopPerfomanceReport= (onData) => {
  return useMutation(authService.countryPerfomance, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useOnholdreportMutation = (onData) => {
  return useMutation(authService.onholdReport, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useReadytoMarkReportMutation = (onData) => {
  return useMutation(authService.readytoMark, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useWalkinCustomerChekingInAppli = (onData) => {
  return useMutation(authService.checkingInApplicationtbl, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useWalkinCustomerCreate = (onWalkinData) => {
  return useMutation(authService.creatingWalkinData, {
    onSuccess: (data) => {
      onWalkinData(data);
    },
  });
};

const useWalkinCustomerList = (onWalkinData) => {
  return useMutation(authService.listWalkinData, {
    onSuccess: (data) => {
      onWalkinData(data);
    },
  });
};

const useWalkinCustomerUpdate = (onData) => {
  return useMutation(authService.updateWalkinData, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useWalkinAssign = (onData) => {
  return useMutation(authService.walkinAssign, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useListWalkinDataForUser = (onWalkinData) => {
  return useMutation(authService.listWalkinDataForUser, {
    onSuccess: (data) => {
      onWalkinData(data);
    },
  });
};

const useAssignedWalkinDelete = (onData) => {
  return useMutation(authService.walkinDelete, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useWhatsappConfirmation = (onData) => {
  return useMutation(authService.whatsappConfirmation, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useApplicantsValidationList = (onData) => {
  return useMutation(authService.validatingApplicationList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useImageUploadMutation = (onData) => {
  return useMutation(authService.userImageUpdate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNotProceedingReportMutation = (onData) => {
  return useMutation(authService.notproceeding, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useOfficevisaReport= (onData) => {
  return useMutation(authService.officevisa, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useOfficeFeePaidkReportMutation = (onData) => {
  return useMutation(authService.officefeepaid, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTopcounselorReportMutation = (onData) => {
  return useMutation(authService.topcounselor, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useExistingApplicantMutation = (onData) => {
  return useMutation(authService.existingApplicant, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useChangeDocumentOrIeltsStatus = (onData) => {
  return useMutation(authService.changeDocumentOrIeltsStatus, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useUniversityFeePayedStd = (onData) => {
  return useMutation(authService.universityfee, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUpdateFeePayedStd = (onData) => {
  return useMutation(authService.updateuniversityfee, {
    onSuccess: (data) => {
      onData(data);
    }, 
  })
};

const useAppliRelatedStaffes = (onData) => {
  return useMutation(authService.relatedstaffes, {
    onSuccess: (data) => {
      onData(data);
    }, 
  })
};

const useCommissionPayed = (Data) => {
  return useMutation(authService.commissionpayed, {
    onSuccess: (data) => {
      Data(data);
    }, 
  })
};

const useCommissionRep = (Data) => {
  return useMutation(authService.commissionreport, {
    onSuccess: (data) => {
      Data(data);
    }, 
  })
};

const useCommissionFiltter = (Data) => {
  return useMutation(authService.commissionrepfiltter, {
    onSuccess: (data) => {
      Data(data);
    }, 
  })
};

const useRelatedAppli = (Data) => {
  return useMutation(authService.relatedapplicants, {
    onSuccess: (data) => {
      Data(data);
    }, 
  })
};

const useCounselorScoreMutation = (onData) => {
  return useMutation(authService.counselorScore, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCounselorScoreCreateMutation = (onData) => {
  return useMutation(authService.createCounselorScore, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useClientexportlistReportMutation = (onData) => {
  return useMutation(authService.clientexportlist, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useClientexportExcelMutation = (onData) => {
  return useMutation(authService.clientexportExcel, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaProgramCreating= (onData) => {
  return useMutation(authService.createusa, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaProgramListing= (onData) => {
  return useMutation(authService.listusa, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaProgramLocationListing= (onData) => {
  return useMutation(authService.usaprogramlocation, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaProgramLocationreate= (onData) => {
  return useMutation(authService.usaprogramlocationcreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaLocationEdit = (onCoutryData) => {
  return useMutation(authService.editUsaLocation, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useUsaLocationDelete = (onCoutryData) => {
  return useMutation(authService.deleteUsaLocation, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useUsaProgramCityListing= (onData) => {
  return useMutation(authService.usaprogramcity, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaProgramCityreate= (onData) => {
  return useMutation(authService.usaprogramcitycreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaCityEdit = (onCoutryData) => {
  return useMutation(authService.editusacity, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useUsaCityDelete = (onCoutryData) => {
  return useMutation(authService.deleteusacity, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useUsaCityForStateListing= (onData) => {
  return useMutation(authService.usacityforstate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useUsaProgramLevelListing= (onData) => {
  return useMutation(authService.usaprogramlevel, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaProgramLevelcreate= (onData) => {
  return useMutation(authService.usaprogramlevelcreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUsaLevelEdit = (onCoutryData) => {
  return useMutation(authService.editUsalevel, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};

const useUsaLevelDelete = (onCoutryData) => {
  return useMutation(authService.deleteUsalevel, {
    onSuccess: (data) => {
      onCoutryData(data);
    },
  });
};



const useAdminUserListDrowpdowns = (onData) => {
  return useMutation(authService.adminUserListDrowpdowns, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const authQueries = {
    useRegisterMutation,
    useLoginMutation,
    useUserListMutation,
    useCountMutation,
    useCollegeMutationList,
    useCollegeCountryMutationList,
    useCollegeMutationView,
    useCollegeMutationAdd,
    useCollegeMutationEdit,
    useCollegeMutationDelete,
    useQualificationMutationList,
    useQualificationMutationView,
    useQualificationMutationAdd,
    useQualificationMutationEdit,
    useQualificationMutationDelete,
    useCountryMutationList,
    useCountryMutationView,
    useCountryMutationAdd,
    useCountryMutationEdit,
    useCountryMutationDelete,
    useNocMutationList,
    useNocMutationView,
    useNocMutationAdd,
    useNocMutationEdit,
    useNocMutationDelete,
    useOfficeMutationList,
    useOfficeMutationView,
    useOfficeMutationAdd,
    useOfficeMutationEdit,
    useOfficeMutationDelete,
    useProgramMutationList,
    useProgramMutationView,
    useProgramMutationAdd,
    useProgramMutationEdit,
    useProgramMutationDelete,
    useVisaTypeMutationList,
    useVisaTypeMutationView,
    useVisaTypeMutationAdd,
    useVisaTypeMutationEdit,
    useVisaTypeMutationDelete,
    useDistrictMutationList,
    useDistrictMutationView,
    useDistrictMutationAdd,
    useDistrictMutationEdit,
    useDistrictMutationDelete,
    useRoleMutationList,
    useRoleMutationView,
    useRoleMutationAdd,
    useRoleMutationEdit,
    useRoleMutationDelete,
    useUniversityMutationList,
    useUniversityMutationView,
    useUniversityMutationAdd,
    useUniversityMutationEdit,
    useUniversityMutationDelete,
    usePnpMutationList,
    usePnpMutationView,
    usePnpMutationAdd,
    usePnpMutationEdit,
    usePnpMutationDelete,
    useRefferalMutationList,
    useRefferalMutationView,
    useRefferalMutationAdd,
    useRefferalMutationEdit,
    useRefferalMutationDelete,
    useIntakeMutationList,
    useIntakeVisaMutationList,
    useIntakeMutationView,
    useIntakeMutationAdd,
    useIntakeMutationEdit,
    useIntakeMutationDelete,
    useUserDetailsMutationList,
    useUserDetailsMutationView,
    useUserDetailsMutationAdd,
    useUserDetailsMutationEdit,
    useUserDetailsMutationDelete, 
    useApplicantMutationAdd,
    useApplicantMutationEdit,
    useApplicantMutationView,
    useApplicantMutationDelete,
    useApplicantMutationBranchManagerList,
    useApplicantMutationRMList,
    useApplicantMutationVisaManagerList, 
    useApplicantAssignMutationList,
    useApplicantAssignProgramMutationList,
    useApplicantAssignAdmissionMutationList,
    useApplicantAssignVisaStaffMutationList,
    useApplicantAssignDocumentStaffMutationList,
    useApplicantAssignSopMutationList,
    useTelecallerMutationList,
    useAssociatesMutationList,
    useEducationMutationList,
    useEducationMutationView,
    useEducationMutationAdd,
    useEducationMutationEdit,
    useEducationMutationDelete,
    useInfoMutationList,
    useInfoMutationAdd,
    useInfoMutationEdit,
    useInfoMutationView,
    useInfoMutationDelete,
    useWorkMutationList,
    useWorkMutationAdd,
    useWorkMutationEdit,
    useWorkMutationView,
    useWorkMutationDelete,
    useAddOtherDetailsCreate,
    useAddOtherDetailsEdit,
    useIntProgramAdd,
    useIntProgramList,
    useIntProgramEdit,
    useIntProgramDelete,
    useIntProgramView,
    useMailSendProgramMutation,
    useNotesUpdateMutation,
    useIntProgramConfirm,
    useIntProgramDragOrderMutation,
    useAcademicAdd,
    useAcademicEdit,
    useAcademicList,
    useAcademicView,
    useAcademicDelete,
    useTravelHistoryAdd,
    useTravelHistoryEdit,
    useTravelHistoryList,
    useTravelHistoryView,
    useTravelHistoryDelete,
    useServicesSoughtAdd,
    useServicesSoughtEdit,
    useServicesSoughtList,
    useServicesSoughtView,
    useServicesSoughtDelete,
    useAppointmentMutationAdd,
    useAppointmentMutationEdit,
    useAppointmentMutationList,
    useAppointmentMutationView,
    useAppointmentMutationDelete,
    useDocDetailMutationList,
    useDocDetailMutationAdd,
    useDocDetailMutationEdit,
    useDocDetailMutationView,
    useDocDetailMutationDelete,
    useAppointClientAdd,
    useAppointClientEdit,
    useAppointClientList,
    useFollowupHistoryList,
    useAppointClientView,
    useFollowupCommentCreateMutation,
    useAppointClientDelete,
    useOverseasAdd,
    useOverseasEdit,
    useOverseasList,
    useOverseasView,
    useOverseasDelete,
    useAssignAdd,
    useAssignEdit,
    useAssignList,
    useAssignView,
    useAssignDelete,
    useProspectAdd,
    useProspectEdit,
    useProspectList,
    useProspectView,
    useProspectDelete,
    useStatusOfProspectsRoleList,
    usePGDMutationAdd,
    usePGDMutationEdit,
    usePGDMutationList,
    usePGDMutationView,
    usePGDMutationDelete,
    useFLDAdd,
    useFLDEdit,
    useFLDList,
    useFLDView,
    useFLDDelete,
    useProStatusAdd,
    useProStatusEdit,
    useProStatusList,
    useProStatusView,
    useProStatusDelete,
    useDocumentAdd,
    useDocumentEdit,
    useDocumentList,
    useDocumentView,
    useAssignRoleView,
    usePaymentAdd,
    usePaymentEdit,
    usePaymentList,
    usePaymentView,
    usePaymentDelete,
    useCommentList,
    useEnglishProAdd,
    useEnglishProList,
    useEnglishProDelete,
    useEnglishProEdit,
    useAddOtherDetailsList,
    useAddOtherDetailsView,
    useFeeAdd,
    useFeeEdit,
    useFeeView,
    useUserFullListMutation,
    useUserBranchList,
    useForgotMutation,
    useProgressChange,
    useEmailValidApplicant,
    useMobileValidApplicant,
    useFileUploadCommon,
    usePdfUploadCommon,
    usePdfMergeCommon,
    usePdfDeleteCommon,
    useExcelUploadMutationCommon,
    useApplicantFileAdd,
    useApplicantFileEdit,
    useApplicantFileList,
    useApplicantFileView,
    useApplicantFileDelete,
    useCounselorAssignList,
    useTelecallerAssignMutation,
    useUserRoleMutationList,
    useUserFollowMutationList,
    useUserRoleMutationView,
    useUserRoleMutationEdit,
    useUserRoleMutationDelete,
    useCounselorDashboardList,
    useCounselorAppoinmentDashboardList,
    useAdminUserMutationList,
    useAdminVisaMutationList,
    useAdminStatusMutationList,
    useAdminOfficeMutationList,
    useAdminRolesMutationList,
    useAdminClientMutationList,
    useCurrencyMutationList,
    useAdmissionProgramWiseMutationList,
    useAdmissionRegisterCreate,
    useAdmissionRegisterMutateUpdate,
    useAdmissionFeeTypeCreateMutation,
    useAdmissionFeeTypeUpdateMutation,
    useFeeCoordinatorMutationList,
    useFeeCoordinatorMutationModalList,
    useFeeCoordinatorMutationModalCreate,
    useFeeCoordinatorMutationModalPaymentList,
    useVisaDataMutationCreate,
    useApplicationMutationViewAll,
    useApplicationMutationMarkRegistration,
    useExcelImportMutationCreate,
    useCounselorReport,
    useClientList,
    useCampusMutationAdd,
    useCampusMutationEdit,
    useCampusMutationView,
    useCampusMutationList,
    useCampusMutationAllList,
    useCampusMutationDelete,
    useCounselorDashboardCountMutation,
    useCounselorDashboardFeePaidMutation,
    useAssociatesDashboardFeePaidMutation,
    useCounselorDashboardVisaMutation,
    useCounselorDashboardRegMutation,
    useCounselorDashboardFollowupMutation,
    useCounselorDashboardPendingMutation,
    useCounselorDashboardAutoFollowupMutation,
    useCounselorDashboardAllPendingMutation,
    useCounselorDashboardTotalMutation,
    useCounselorDashboardActiveMutation,
    useCounselorDashboardConfirmMutation,
    useBranchManagerDashboardActiveMutation,
    useRegionalManagerDashboardCount,
    useSeniorProgramManagerDashboardActiveMutation,
    useUniqueStatusReport,
    useTelecallerDashboardCount,
    useTargetOfficeMutationList,
    useTargetMutationView,
    useTargetMutationAdd,
    useTargetTotalCountView,
    useTelecallerGraph,
    useCounselorDashboardMutationTargetCount,
    useCounselorFeePaidMutationDelete,
    useAppStaffStatusMutationAdd,
    useAppStaffStatusMutationEdit,
    useAppStaffStatusMutationList,
    useAppStaffStatusMutationView,
    useAppStaffStatusMutationDelete,
    useImportApplicantMutationList,
    useImportApplicantMutationDelete,
    useFeeTypeMutationList,
    useFeePaidTypeMutationAdd,
    useFeePaidTypeMutationView,
    useFeePaidTypeMutationEdit,
    useFeePaidTypeMutationList,
    useFeePaidTypeMutationDelete,
    useLeadTypeMutationAdd,
    useLeadTypeMutationEdit,
    useLeadTypeMutationList,
    useLeadTypeMutationView,
    useLeadTypeMutationDelete,
    useCounselorDashboardIELTSCountMutation,
    useCounselorDashboardIELTSPendingMutation,
    useCounselorDashboardTOTCountMutation,
    useCounselorDashboardTOTPendingMutation,
    useEmployeeReport,
    useCounselorRegistrationReport,
    useAssoiciatesDashboardMutation,
    useCEODashboardMutation,
    useCEODashboardTotalMutation,
    useCEODashboardCFYMutation,
    useCEODashboardCountryMutation,
    useManagerDashboardMutation,
    useLeaderBoardCount,
    useCEOClientListMutation,
    useMarkRegisterPendingMutation,
    useCountrywiseReport,
    useUniqueCountrywiseReport,
    useVisaStaffDashboardCountMutation,
    useProgramDashboardCountMutation,
    useAdmissionDashboardCountMutation,
    useFeeDashboardCountMutation,
    useApplicantList,
    useCEONotInterestedMutation,
    useCEONotQualifiedMutation,
    useCEOFeePaidMutation,
    useCEOVisaApprovedMutation,
    useNotificationList,
    useNotificationReadMutation,
    useNotificationReadAllMutation,
    useVisaDataMutatuionCreate,
    useVisaDataMutatuionList,
    useVisaDataMutatuionView,
    useEventCreateMutationCreate,
    useEventCreateMutationList,
	  useCountryApplicantList,
    useCeoDashboardReferenceCount,
    useCeoDashboardBranchCount,
    useCounselorWiseListing,
    useBulkAssignMutation,
    useSearchAllApplicant,
    useBranchwiseReport,
    useBranchTargeteReport,
    useCountryTargeteReport,
    useFeeStatusReport,
    useExecutiveApplicant,
    useExportPdf,
    useApplicationLoanMutationList,
    useApplicantExeConfirmMutation,
    useMarkRegMutationCounts,
    useGicAssignMutation,
    useProgramDocumentCreateMutation,
    useProgramDocumentListMutation,
    useProgramDocumentDeleteMutation,
    useExportCEOAllApplicantMutation,
    useDailyFollowReportMutation,
    useDailyFollowReportCEOMutation,
    useCounselorAssociatesMutation,
    useAddNewCounselorAssociatesMutation,
    useDeleteCounselorAssociatesMutation,
    useOfficeCounselorAssociatesMutation,
    useOfficeWiseCounselorListMutation,
    useOfficeWiseOnlyCounselorListMutation,
    useEnglishProfiencyStatusChangeMutation,
    useAllNotInterestOrQualifiedListMutation,
    useReAssignCounselorMutation,
    useCaptureApplicantTelecallerMutation,
    useApplicantProgramPublicListMutation,
    useApplicantProgramPublicUpdateMutation,
    useProgramSuggestionListMutation,
    useTopPerformListingMutation,
    useAssociatesReportMutation,
    useAssociatesReportListMutation,
    useFinishedClientsListMutation,
    useFinishedClientsVisaTypeListMutation,
    useVisaWiseStatusListingMutation,
    useFinishedClientsOfficeListMutation,
    useRoleWiseStatusListingMutation,
    useVisaWiseStatusCreateMutation,
    useRoleWiseStatusCreateMutation,
    useAssociateTileListMutation,
    useRoleWiseListingUsersMutation,
    useFollowUpTodayOfficeWiseMutation,
    useFollowUpPendingOfficeWiseMutation,
    useFollowUpTodaySPMMutation,
    useFollowUpPendingSPMMutation,
    useAdvancedSearchMutation,
    useApplicantFormMastersList,
    useChangePasswordMutation,
    useOldDocumentListing,
    useFollowupTodayBranchManagerCount,
    useFollowupPendingBranchManagerCount,
    useBranchManagerFollowupDropDown,
    useSeniorProgramManagerFollowupDropDown,
    useCounselorApplicantList,
    useSeniorManagerListingMaster,
    useSeniorManagerAddMaster,
    useSeniorManagerRemoveMaster,
    useSeniorManagerUsersList,
    useCounselorRegistrationCountryReport,
    useFairApplicationCreate,
    useFairApplicationList,
    useFairApplicationEdit,
    useApplicationSPMMyclientListMutation,
    useApplicationAssignedSPCMyclientListMutation,
    useExportFairStaffApplication,
    useProgramSuggestionMasterList,
    useSopStaffDashboardCounts,
    useApplicantAssignedUsersDetails,
    useCounselorCountryApplicantlist,
    useProgramPendingList,
    useChangeLeadStatus,
    useAdmissionstaffReport,
    usePgmsuggestionReport,
    usePgmsuggestionReportMaster,
    usePgmsuggestionIntakeMaster,
    useCounselorNotinterestedReport,
    useCountryTopPerfomanceReport,
    useOnholdreportMutation,
    useReadytoMarkReportMutation,
    useWalkinCustomerChekingInAppli,
    useWalkinCustomerCreate,
    useWalkinCustomerList,
    useWalkinCustomerUpdate,
    useWalkinAssign,
    useListWalkinDataForUser,
    useAssignedWalkinDelete,
    useReadytoMarkReportMutation,
    useWhatsappConfirmation,
    useApplicantsValidationList,
    useImageUploadMutation,
    useNotProceedingReportMutation,
    useOfficevisaReport,
    useOfficeFeePaidkReportMutation,
    useTopcounselorReportMutation,
    useExistingApplicantMutation,
    useOfficeFeePaidkReportMutation,
    useChangeDocumentOrIeltsStatus,
    useUniversityFeePayedStd,
    useUpdateFeePayedStd,
    useAppliRelatedStaffes,
    useCommissionPayed,
    useCommissionRep,
    useCommissionFiltter,
    useRelatedAppli,
    useChangeDocumentOrIeltsStatus,
    useCounselorScoreMutation,
    useCounselorScoreCreateMutation,
    useClientexportlistReportMutation,
    useClientexportExcelMutation,
    useCounselorScoreMutation,
    useCounselorScoreCreateMutation,
    useUsaProgramCreating,
    useUsaProgramListing,
    useUsaProgramLocationreate,
    useUsaProgramLocationListing,
    useUsaLocationEdit,
    useUsaLocationDelete,
    useUsaProgramCityListing,
    useUsaProgramCityreate,
    useUsaCityEdit,
    useUsaCityDelete,
    useUsaCityForStateListing,
    useUsaProgramLevelListing,
    useUsaProgramLevelcreate,
    useUsaLevelEdit,
    useUsaLevelDelete,
    useClientexportExcelMutation,
    useAdminUserListDrowpdowns
};

export default authQueries;
